<template>
<div class="page_admin_addNews">
    <v-container>
        <v-layout row wrap>
            <v-flex md8>
                <v-form ref="form">

                    <v-text-field
                    v-model="title"
                    label="標題"
                    prepend-icon="title"
                    :rules="[ v => !!v || '請輸入標題', ]"
                    ></v-text-field>

                    <v-text-field
                    v-model="link"
                    label="連結"
                    prepend-icon="link"
                    ></v-text-field>

                    <v-select
                    v-model="announcer"
                    :items="announcers"
                    label="來源"
                    prepend-icon="rss_feed"
                    min-width="300px"
                    offset-y
                    ></v-select>

                    <v-menu
                    ref="menu"
                    v-model="datePicker"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                            v-model="date"
                            label="發布日期"
                            prepend-icon="event"
                            readonly
                            v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                        v-model="date"
                        locale="zh-tw"
                        scrollable
                        @input="datePicker = false"
                        >
                        </v-date-picker>
                    </v-menu>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click="submit">發布</v-btn>
                    </v-card-actions>

                </v-form>
            </v-flex>

            <v-snackbar v-model="snackbar" :timeout="timeout">
                發布成功
                <v-btn flat @click="snackbar = false">OK</v-btn>
            </v-snackbar>
        </v-layout>
    </v-container>
</div>
</template>

<script>
export default {
    data() {
        return {
            title: '',
            link: '',
            announcer: null,
            date: new Date().toISOString().substr(0, 10),
            announcers: [
                { value: 'ceec',    text: '大考中心' },
                { value: 'cac',     text: '甄委會' },
                { value: 'uac',     text: '考分會' },
                { value: 'jbcrc',   text: '招聯會' },
                { value: 'cape',    text: '術聯會' },
            ],
            datePicker: false,
            snackbar: false,
            timeout: 3000,
        }
    },
    methods: {
        async submit() {
            if (this.$refs.form.validate()) {
                await ___.api.news.addNews({
                    status: 'published',
                    announcer: this.announcer,
                    date: this.date || new Date,
                    type: [],
                    titles: [{
                        title:  this.title,
                        link:   this.link,
                    }]
                })
                this.snackbar = true
                this.title = ''
                this.link = ''
                this.$refs.form.resetValidation()
            }
        }
    },
}
</script>

<style lang="scss">
</style>
