export default {
    
    // utilities
    fetchServer, fixedEncodeURIComponent, toUrlEncoded,

    // ==================== add api calling functions here ====================
    user: {
        login:      ( body, option ) => fetchServer('/user/login', {body, ...option}),
        logout:     ( option ) => fetchServer('/user/logout', option),
        create:     ( body, option ) => fetchServer('/user/create', {body, ...option}),
        getInfo:    ( option ) => fetchServer('/user/getInfo', option),
        getData:    ( items, option ) => fetchServer('/user/getData', {body: { items }, ...option}),
        setData:    ( body,  option ) => fetchServer('/user/setData', {body, ...option}),
    },
    news: {
        getPending:     ( option ) => fetchServer('/news/getPending', option),
        getPublished:   ( option ) => fetchServer('/news/getPublished', option),
        getDeleted:     ( option ) => fetchServer('/news/getDeleted', option),
        setState:       ( body, option ) => fetchServer('/news/setState', {body, ...option}),
        addNews:        ( body, option ) => fetchServer('/news/addNews', {body, ...option}),
    },
    // ========================================================================
}

// a wrap of fetch api
async function fetchServer( url, option = {} ) {
    return fetch( option.url || `/api${url}`,
    {
        method: option.method || 'post',
        headers:
        ( option.body ) &&
        ( option.contentType == 'multipart/form-data' ||
        ( option.contentType == 'application/x-www-form-urlencoded' && !option.x_obj)) ? undefined :
        {
            'X-Proxy-Url': option.proxyUrl || '',
            'Content-Type': option.contentType || 'application/json',
            'ukey': option.ukey || '',
        },
        body: option.rawBody ? option.rawBody : option.x_obj ?
            toUrlEncoded( option.x_obj ) : option.contentType ? option.body ?
            new FormData( option.body ) : undefined :
            JSON.stringify( option.body || undefined )
    })
    .then( res => { return res.json() })
    .catch( err => { console.log(err) })
}

// enhance version of encodeURIComponent()
function fixedEncodeURIComponent(str) {
    return encodeURIComponent(str).replace(/[!'()*]/g, c => 
        `%${c.charCodeAt(0).toString(16)}`
    )
}

// convert object to x-www-form-urlencoded format
function toUrlEncoded(obj) {
    return Object.keys(obj).map( key =>
        `${fixedEncodeURIComponent(key)}=${fixedEncodeURIComponent(obj[key])}`
    ).join('&')
}
