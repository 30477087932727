<template>
<div class="page_home_download col-lg-8 mb-8">

    <!-- title -->
    <v-chip class="font-size:3rem;" text-color="white" color="primary">資料下載</v-chip>
    <br><br>

    <!-- Lists -->
    <v-list>
        <v-subheader>宣導手冊</v-subheader>
        <v-list-tile
        v-for="file in files1"
        :key="file.name"
        :href="`/download/${file.name}.pdf`"
        target="_blank"
        ripple
        >
        <v-list-tile-content>
            <v-list-tile-title>{{ file.name }}</v-list-tile-title>
        </v-list-tile-content>
        <v-list-tile-action>
            <v-subheader inset>{{ file.size }}</v-subheader>
        </v-list-tile-action>
        </v-list-tile>

        <v-divider></v-divider>

        <v-subheader>方案介紹</v-subheader>
        <v-list-tile
        v-for="file in files2"
        :key="file.name"
        :href="`/download/${file.name}.pdf`"
        target="_blank"
        ripple
        >
        <v-list-tile-content>
            <v-list-tile-title>{{ file.name }}</v-list-tile-title>
        </v-list-tile-content>
        <v-list-tile-action>
            <v-subheader inset>{{ file.size }}</v-subheader>
        </v-list-tile-action>
        </v-list-tile>
    </v-list>
    
</div>
</template>

<script>
export default {
    data() {
        return {
            files1: [
			    { name: '114學年度學生手冊', size: '14.9MB', },
                { name: '113學年度學生手冊', size: '41.3MB', },
			    { name: '112學年度學生手冊', size: '2.56MB', },
                { name: '111學年度學生手冊', size: '30.1MB', },
                { name: '110學年度學生手冊', size: '5.02MB', },
                { name: '109學年度學生手冊', size: '8.32MB', },
                { name: '108學年度學生手冊', size: '4.57MB', },
                { name: '107學年度學生手冊', size: '4.58MB', },
                { name: '106學年度學生手冊', size: '4.33MB', },
                { name: '105學年度學生手冊', size: '4.53MB', },
                { name: '104學年度學生手冊', size: '2.47MB', },
                { name: '103學年度學生手冊', size: '2.04MB', },
                { name: '102學年度學生手冊', size: '2.74MB', },
                { name: '101學年度學生手冊', size: '2.00MB', },
                { name: '100學年度學生手冊', size: '1.06MB', },
            ],
            files2: [
                { name: '100學年度大學多元入學方案介紹', size: '239KB', },
                { name: '100學年度大學多元入學家長說明會', size: '3.92MB', },
                { name: '100學年度大學甄選入學招生試務作業介紹', size: '724KB', },
                { name: '100學年度繁星推薦高中作業概況介紹', size: '1.25MB', },
            ],
        }
    },
}
</script>

<style lang="scss">
.page_home_download {
    .v-list a {
        text-decoration: none;
    }
    .v-chip__content {
        font-size: 1.5rem;
    }
}
</style>
