// root hook
import db from 'common/db'
import api from 'common/api'
import cookie from 'util/cookie.js'
import component from 'util/component'
import Vue from 'vue'

export default {
    beforeCreate () {
        // root data and methods
        const ___ = {
            db, api, ...cookie, ...component,
            state: this.$store.state,
            userData: db.getUserData(),
            appData: db.getAppData(),
            idb: db.getDB(),
        }

        // add to global or vue instance
        window.___ = ___
        window.vue = this
        Vue.prototype.___ = ___
        Object.assign(window, ___)
        Object.assign(Vue.prototype, ___)
    }
}
