var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page_admin" },
    [
      _c(
        "navbar",
        {
          attrs: {
            Title: "後台管理",
            Open: "",
            Below: "",
            Items: _vm.panelItems,
          },
        },
        [
          _c(
            "router-link",
            {
              staticClass: "v-btn--flat",
              attrs: { slot: "toolbar", to: "/", tag: "v-btn" },
              slot: "toolbar",
            },
            [_vm._v("返回多元入學升學網")]
          ),
          _vm._v(" "),
          _c("avatar", { attrs: { slot: "toolbar" }, slot: "toolbar" }),
          _vm._v(" "),
          !_vm.logedin
            ? _c(
                "v-btn",
                { attrs: { slot: "toolbar", flat: "" }, slot: "toolbar" },
                [_vm._v("登入")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.logedin
            ? _c(
                "v-btn",
                {
                  attrs: { slot: "toolbar", flat: "" },
                  on: { click: _vm.logout },
                  slot: "toolbar",
                },
                [_vm._v("登出")]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("router-view", {
            attrs: { Items: _vm.panelItems },
            on: { setState: _vm.setState },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }