<template>
<div class="page_admin_newsManager">
    <v-progress-linear v-show="!news[0]" :indeterminate="true"></v-progress-linear>
    <v-container fill-height>
        <v-layout align-center>
            <v-flex>
                <!-- news -->
                <v-list two-line>
                    <transition-group name="slide">
                    <v-list-tile
                    v-for="(item, index) in news[page-1]"
                    :key="item._id"
                    >
                        <!-- <v-list-tile-action>
                            <v-checkbox></v-checkbox>
                        </v-list-tile-action> -->

                        <v-list-tile-sub-title>{{ item.date.slice(0,10) }}</v-list-tile-sub-title>
                        
                        <v-chip small label text-color="white" v-if="item.announcer == 'ceec'"  color="primary">大考中心</v-chip>
                        <v-chip small label text-color="white" v-if="item.announcer == 'cac'"   color="cyan"   >甄委會</v-chip>
                        <v-chip small label text-color="white" v-if="item.announcer == 'uac'"   color="amber"  >考分會</v-chip>
                        <v-chip small label text-color="white" v-if="item.announcer == 'jbcrc'" color="green"  >招聯會</v-chip>
                        <v-chip small label text-color="white" v-if="item.announcer == 'cape'"  color="indigo" >術聯會</v-chip>
                            
                        <v-list-tile-title>
                            <v-btn flat small
                            v-for="t in item.titles"
                            :key="t.title"
                            :href="t.link" target="_blank"
                            >{{ t.title }}</v-btn>
                        </v-list-tile-title>
            
                        <v-list-tile-action
                        v-if="$route.path != '/admin/published'"
                        @click="$emit('setState', [item], 'published'); remove(index);"
                        >
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn icon ripple v-on="on">
                                    <v-icon color="grey darken-1">done</v-icon>
                                </v-btn>
                            </template>
                            <span>發佈</span>
                        </v-tooltip>
                        </v-list-tile-action>
                        
                        <v-list-tile-action
                        v-if="$route.path != '/admin/deleted'"
                        @click="$emit('setState', [item], 'deleted'); remove(index);"
                        >
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn icon ripple v-on="on">
                                    <v-icon color="grey darken-1">delete</v-icon>
                                </v-btn>
                            </template>
                            <span>刪除</span>
                        </v-tooltip>
                        </v-list-tile-action>
                    </v-list-tile>
                    </transition-group>
                </v-list>

                <!-- pagination -->
                <div class="text-xs-center">
                    <v-pagination v-show="news[0]" v-model="page" :length="news.length"></v-pagination>
                </div>
            </v-flex>
        </v-layout>
    </v-container>
</div>
</template>

<script>
export default {
    props: ['Items'],
    data() {
        return {
            news: [],
            page: 1,
            pageSize: 15,
        }
    },
    async beforeMount () {
        // get news
        const Newsitems = this.Items.find(g=>g.groupName=='項目')
        for (const i of Newsitems.items) {
            this.getNews(i.to).then(news => {
                // update news number
                i.name = i.name.replace(/\((.*?)\)/g, `(${news.length})`)
                // set news to selected one
                if (i.to == this.$route.path || (this.$route.path == '/admin' && i.to == '/admin/pending'))
                    this.news = this.chunkNews(news, this.pageSize)
            })
        }
    },
    watch: {
        async $route (to, from) {
            // get news
            if (['/admin/pending', '/admin/published', '/admin/deleted'].includes(to.path)) {
                this.news = []
                this.page = 1
                const news = await this.getNews(to.path)
                this.news = this.chunkNews(news, this.pageSize)
            }
        },
    },
    methods: {
        getNews (path) {
            if      (path == '/admin/pending')      return ___.api.news.getPending()
            else if (path == '/admin/published')    return ___.api.news.getPublished()
            else if (path == '/admin/deleted')      return ___.api.news.getDeleted()
            else                                    return ___.api.news.getPending()
        },
        remove (index) {
            // remove news
            this.news[this.page-1].splice(index, 1)
            // update page chunk
            this.news = this.chunkNews([].concat(...this.news), this.pageSize)
        },
        chunkNews (news, size) {
            let results = []
            while (news.length) results.push(news.splice(0, size))
            return results
        },
    },
}
</script>

<style lang="scss">
.page_admin_newsManager {
    // background-color: #1b2d5a;
    // background-image: linear-gradient(135deg, #1b2d5a 0%, #030509 100%);

    .v-list {
        background-color: #ffffff00;
    }
    .v-list__tile:hover {
        background-color: #00000023 !important;
        cursor: default;
    }
    .v-list__tile__sub-title {
        max-width: 100px;
    }
    .v-btn {
        margin: 0;
    }
    div[role=progressbar] {
        margin: 0;
    }

    // transition 
    .slide-enter {
        transform: translateY(500px);
    }
    .slide-enter-active {
        transition: transform 0.3s ease-out;
    }
    .slide-enter-to {
        transform: translateY(0px);
    }
    .slide-leave {
        transform: translateX(0px);
    }
    .slide-leave-active {
        transition: transform 0.7s ease-out;
        position: absolute;
    }
    .slide-leave-to {
        transform: translateX(2000px);
    }
    .slide-move {
      transition: transform 1s;
    }
}
</style>
