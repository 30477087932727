<template>
<div class="page_admin">
    <!-- navbar -->
    <navbar
    Title="後台管理"
    Open
    Below
    :Items="panelItems"
    >
        <!-- navbar buttons -->
        <router-link
            slot="toolbar" to="/" tag="v-btn" class="v-btn--flat"
        >返回多元入學升學網</router-link>
        <avatar slot="toolbar"></avatar>
        <v-btn  slot="toolbar" v-if='!logedin' flat >登入</v-btn>
        <v-btn  slot="toolbar" v-if=' logedin' flat @click="logout">登出</v-btn>

        <!-- content -->
        <router-view :Items="panelItems" @setState="setState"></router-view>

    </navbar>
</div>
</template>

<script>
export default {
    data() {
        return {
            logedin: ___.getCookie('account') ? true : false,
            panelItems: [
                {
                    groupName: '項目',
                    items: [
                        { name: '待處理 ()', to: '/admin/pending',     icon: 'new_releases', selected: this.$route.path == '/admin/pending' || this.$route.path == '/admin'},
                        { name: '已發布 ()', to: '/admin/published',   icon: 'done'        , selected: this.$route.path == '/admin/published'},
                        { name: '已刪除 ()', to: '/admin/deleted',     icon: 'delete'      , selected: this.$route.path == '/admin/deleted'},
                    ]
                },
                {
                    groupName: '功能',
                    items: [
                        { name: '新增消息', to: '/admin/add-news',     icon: 'add'         , selected: this.$route.path == '/admin/add-news'},
                    ]
                },
                {
                    groupName: '設定',
                    items: [
                        { name: '帳號管理', to: '/admin/update-user',     icon: 'edit'         , selected: this.$route.path == '/admin/update-user'},
                    ]
                }
            ],
        }
    },
    async created () {
        if (!this.logedin) this.$router.replace('/login')
    },
    watch: {
        async $route (to, from) {
            // set selected state
            const items = this.panelItems.reduce((items, i)=>items.concat(i.items), [])
            for (const i of items) {
                if (i.to == to.path) i.selected = true
                else                 i.selected = false
            }
        },
    },
    methods: {
        async logout () {
            await ___.api.user.logout()
            this.$router.push('/')
        },
        async setState (items, state) {
            // call api
            await ___.api.news.setState({ news: items.map(i=>i._id), state})
            // calculate news number
            const Newsitems = this.panelItems.find(g=>g.groupName=='項目')
            for (const i of Newsitems.items) {
                // update src
                if (i.to == this.$route.path || (this.$route.path == '/admin' && i.to == '/admin/pending'))
                    i.name = i.name.replace(/\((.*?)\)/g, (_,$1)=>`(${parseInt($1) - items.length})`)
                // update dst
                if (state == 'published' && i.to == '/admin/published')
                    i.name = i.name.replace(/\((.*?)\)/g, (_,$1)=>`(${parseInt($1) + items.length})`)
                if (state == 'deleted' && i.to == '/admin/deleted')
                    i.name = i.name.replace(/\((.*?)\)/g, (_,$1)=>`(${parseInt($1) + items.length})`)
            }
        },
    },
}
</script>

<style lang="scss">
</style>
