import $ from 'util/component'

export default {
    navbar: {
        template:   require('component/navbar/-navbar.html'),
        style:      require('component/navbar/-navbar.scss'),
        /*data*/ ...require('component/navbar/data.js').default,
        /*hook*/ ...require('component/navbar/hook.js').default,
        methods:    require('component/navbar/method.js').default,
    },
    avatar:         require('component/avatar.vue').default,
}
