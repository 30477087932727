var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page_home_download col-lg-8 mb-8" },
    [
      _c(
        "v-chip",
        {
          staticClass: "font-size:3rem;",
          attrs: { "text-color": "white", color: "primary" },
        },
        [_vm._v("常見問題")]
      ),
      _vm._v(" "),
      _c("br"),
      _c("br"),
      _vm._v(" "),
      _c("div", { staticClass: "card mb-4" }, [
        _c("div", { staticClass: "card-body reminder" }, [
          _c("h2", { staticClass: "card-title" }, [_vm._v("招生")]),
          _vm._v(" "),
          _c("p", { staticClass: "card-text" }),
          _vm._v(" "),
          _c("p"),
          _vm._v(" "),
          _c("h5", [
            _vm._v(
              "13.我已錄取特殊選才且完成報到，可以參加大學多元入學或四技二專各招生管道嗎？"
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "qa-text" }, [
            _c("img", {
              attrs: { height: "25", src: _vm.content.qa, width: "25" },
            }),
            _vm._v("不可以。\n\t\t\t\t\t"),
            _c("br"),
            _vm._v(
              "經特殊選才錄取且完成報到之考生，未於錄取學校規定之期限內放棄錄取資格者，不得參加當學年度大學繁星推薦、大學申請入學、大學分發入學、科技校院繁星計畫聯合推薦甄選入學、科技校院四年制及專科學校二年制特殊選才入學聯合招生、科技校院日間部四年制申請入學聯合招生、四技二專之技優保送入學、技優甄審入學、甄選入學、日間部聯合登記分發入學。另溫馨提醒經特殊選才錄取且完成報到之考生，如符合就讀高中繁星推薦資格，於就讀高中辦理高中校內進行推薦作業時，應主動吿知就讀高中，以利高中校內推薦作業進行。\n\t\t\t\t"
            ),
          ]),
          _vm._v(" "),
          _c("p"),
          _vm._v(" "),
          _c("h5", [
            _vm._v("14.請問繁星推薦成績之總平均計算方式及高中推薦程序？"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "qa-text" }, [
            _c("img", {
              attrs: { height: "25", src: _vm.content.qa, width: "25" },
            }),
            _vm._v(
              "繁星推薦自100學年度納入多元入學方案迄今，涉學業成績總平均計算方式、高中推薦程序均未改變，說明如下：\n\t\t\t\t\t"
            ),
            _c("br"),
            _vm._v(
              "(1)\t繁星推薦所採計「各學期學業成績總平均」之計算：各高中應依據高級中等學校學生學習評量辦法之規定辦理。但如有重修或補考之科目，應以原成績辦理；重讀之科目，應以再次選讀所得之成績辦理。\n\t\t\t\t\t"
            ),
            _c("br"),
            _vm._v(
              "※依「高級中等學校學生學習評量辦法」第七條第一項規定：學期學業成績總平均之計算，為各科目學期學業成績乘以各該科目學分數所得之總和，再除以總學分數。\n\t\t\t\t\t"
            ),
            _c("br"),
            _vm._v(
              "(2)\t各高中辦理「繁星推薦」推薦作業：依大學招生委員會聯合會大學繁星推薦招生規定，應成立推薦委員會，並訂定推薦原則及程序等有關規定，在校內公布後審慎辦理。招生簡章並未統一訂定各高中學校辦理推薦之方式，係考量各高中學校之推薦序會因各校科別不同、學生數及課程情形等迥異，仍宜由各高中自訂公平合宜之推薦機制，提校內推薦委員會同意後公布辦理。因此，高中應明訂公平、公正、公開之推薦原則(含成績處理、推薦資格、推薦順序等審定原則)及程序，並於學生入學時詳細說明及公布。\n\t\t\t\t"
            ),
          ]),
          _vm._v(" "),
          _c("p"),
          _vm._v(" "),
          _c("h5", [
            _vm._v(
              "15.我同時錄取大學申請入學招生及科技校院日間部四年制申請入學聯合招生，想走科技校院四年制申請入學，卻發現沒有在期限內放棄大學招生管道的錄取資格，是否還有其他方法挽救？"
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "qa-text" }, [
            _c("img", {
              attrs: { height: "25", src: _vm.content.qa, width: "25" },
            }),
            _vm._v("無法補救。\n\t\t\t\t\t"),
            _c("br"),
            _vm._v(
              "若超過招生簡章規定的放棄錄取時間，則無法再補救。提醒同學，無論有意參與技專院校之升學管道，或大學繁星推薦、申請入學招生管道，皆必須注意其招生簡章所定之重要日程。\n\t\t\t\t"
            ),
          ]),
          _vm._v(" "),
          _c("p"),
          _vm._v(" "),
          _c("h5", [
            _vm._v(
              "16.若想放棄繁星推薦錄取的校系，改走分發入學管道，卻錯過繁星推薦的放棄時間點，還可以補放棄嗎？"
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "qa-text" }, [
            _c("img", {
              attrs: { height: "25", src: _vm.content.qa, width: "25" },
            }),
            _vm._v("不可以。\n\t\t\t\t\t"),
            _c("br"),
            _vm._v(
              "承接上題，同學必須特別留意放棄時間，一旦錯過就無法再補放棄。\n\t\t\t\t"
            ),
          ]),
          _vm._v(" "),
          _c("p"),
          _vm._v(" "),
          _c("h5", [
            _vm._v(
              "17.我通過繁星推薦第8類學群第1階段篩選，可以參加申請入學管道嗎？"
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "qa-text" }, [
            _c("img", {
              attrs: { height: "25", src: _vm.content.qa, width: "25" },
            }),
            _vm._v(
              "可以，但不得再於申請入學管道重複報名已通過篩選(繁星推薦第 8 類學群)之同一大學之同一校系。\n\t\t\t\t"
            ),
          ]),
          _vm._v(" "),
          _c("p"),
          _vm._v(" "),
          _c("h5", [
            _vm._v(
              "18.我錄取繁星推薦第8類學群，可以參加申請入學登記就讀志願序嗎？"
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "qa-text" }, [
            _c("img", {
              attrs: { height: "25", src: _vm.content.qa, width: "25" },
            }),
            _vm._v(
              "不可以，第8類學群錄取生，不得上網登記就讀志願序，亦不得參加申請入學統一分發。\n\t\t\t\t"
            ),
          ]),
          _vm._v(" "),
          _c("p"),
          _vm._v(" "),
          _c("h5", [
            _vm._v("19.大學端是怎麼審查我的審查資料？準備方向是什麼呢？ "),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "qa-text" }, [
            _c("img", {
              attrs: { height: "25", src: _vm.content.qa, width: "25" },
            }),
            _vm._v(
              "為了讓學生製作審查資料時，有更明確的依循方向，以113學年度為例：\n\t\t\t\t\t"
            ),
            _c("br"),
            _vm._v(
              "(1)\t各大學校系於113年3月1日前公告申請入學審查資料準備指引，提供學生第二階段審查資料準備參考。且為了方便考生及家長查閱，自113學年度起，甄選會已彙整各大學準備指引連結頁面，考生及家長得在113年3月1日起至"
            ),
            _c(
              "a",
              { attrs: { href: "https://www.cac.edu.tw/", target: "_blank" } },
              [_vm._v("甄選會網址")]
            ),
            _vm._v(
              "，選擇「申請入學」，進入「審查資料準備指引」項下查詢。\n\t\t\t\t\t"
            ),
            _c("br"),
            _vm._v("(2)\t招聯會已製作"),
            _c(
              "a",
              {
                attrs: {
                  href: "https://www.youtube.com/@user-vt9ed3nn1j",
                  target: "_blank",
                },
              },
              [_vm._v("三分鐘小講堂系列影片")]
            ),
            _vm._v(
              "，希幫助學生能更了解大學端怎麼審查資料、看的重點是什麼；也可以到"
            ),
            _c(
              "a",
              { attrs: { href: "http://www.jbcrc.edu.tw/", target: "_blank" } },
              [_vm._v("招聯會網站")]
            ),
            _vm._v("查詢或直接電話洽詢(02)2368-1913。\n\t\t\t\t"),
          ]),
          _vm._v(" "),
          _c("p"),
          _vm._v(" "),
          _c("h5", [
            _vm._v(
              "20.大學申請入學第2階段指定項目甄試審查資料需要印製精美、面試時需穿著西裝或套裝？ "
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "qa-text" }, [
            _c("img", {
              attrs: { height: "25", src: _vm.content.qa, width: "25" },
            }),
            _vm._v("不需要。\n\t\t\t\t\t"),
            _c("br"),
            _vm._v(
              "招聯會澄清，自103學年度起已經全面實施線上書審，完全不用花錢印製。申請入學第二階段中指定項目審查資料早已不是「集點制」，採「全方位審查」，許多大學審查委員更表示，是否有「第三方製作」，大學教授有能力分辨，看的重點是內容與配合的素材跟申請系所之間的關聯，利用面試機會驗證書審資料的真實性；尤其提醒審查委員要挑的是人才，與穿著打扮、儀容沒關係，一般性的穿著即可。\n\t\t\t\t"
            ),
          ]),
          _vm._v(" "),
          _c("p"),
          _vm._v(" "),
          _c("h5", [
            _vm._v(
              "21.應屆畢業生報考大學申請入學時，要如何上傳學習歷程檔案給大學呢？"
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "qa-text" }, [
            _c("img", {
              attrs: { height: "25", src: _vm.content.qa, width: "25" },
            }),
            _vm._v(
              "依照教育部國民及學前教育署規定，每學年提交學習歷程檔案至學習歷程中央資料庫，於報考大學申請入學通過第一階段的考生，針對校系要求之課程學習成果、多元表現等審查資料，即可於資料庫中勾選後上傳給大學。\n\t\t\t\t"
            ),
          ]),
          _vm._v(" "),
          _c("p"),
          _vm._v(" "),
          _c("h5", [
            _vm._v(
              "22.大學申請入學通過一階篩選後，可以提前熟悉網路上傳(勾選)審查資料之操作介面及作業流程嗎？"
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "qa-text" }, [
            _c("img", {
              attrs: { height: "25", src: _vm.content.qa, width: "25" },
            }),
            _vm._v("可以。\n\t\t\t\t\t"),
            _c("br"),
            _vm._v(
              "甄選會為利考生預先瞭解並熟悉申請入學第二階段網路上傳(勾選)審查資料之操作介面及作業流程，訂於114年4月中旬開放測試系統供考生實作演練及提前檢視歷程資料。測試系統網址為：https://www.cac.edu.tw/，進入後選擇「申請入學」網頁，再點選「審查資料上傳」項下之「第二階段審查資料上傳系統(測試版)」，即可進行審查資料上傳測試作業。\n\t\t\t\t\t"
            ),
            _c("br"),
            _vm._v(
              "考生若為當學年度學術群之應屆畢業生，測試版期間登入後可檢視學習歷程中央資料庫第1至第4學期之學習歷程檔案資料；正式版期間登入後可檢視學習歷程中央資料庫第1至第6學期之學習歷程檔案資料。\n\t\t\t\t\t"
            ),
            _c("br"),
            _vm._v(
              "針對甄選會系統內，其考生檢視的修課紀錄、課程學習成果、多元表現等均由學習歷程中央資料庫提供，如對於檔案資料有疑義，務必依簡章規定期限內立即向就讀高中進行反映，以維護自身權益。\n\t\t\t\t\t"
            ),
            _c("br"),
            _vm._v(
              "若考生於操作上傳(勾選)時有疑問，可至「申請入學」網站，點選「審查資料上傳」頁面「審查資料上傳相關事項」之「審查資料上傳常見問題Q&A」查看，或是致電甄選會(05-2721799)洽詢。\n\t\t\t\t"
            ),
          ]),
          _vm._v(" "),
          _c("p"),
          _vm._v(" "),
          _c("h5", [
            _vm._v(
              "23.進行審查資料上傳前，我所使用的設備需要有哪些作業系統或軟體？ "
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "qa-text" }, [
            _c("img", {
              attrs: { height: "25", src: _vm.content.qa, width: "25" },
            }),
            _vm._v(
              "建議使用桌上型電腦或筆記型電腦進行審查資料上傳，且電腦建議安裝Adobe官方網站Adobe Acrobat Reader之PDF 閱讀器最新版本，此外，建議使用Microsoft office2007以上版本之文書處理軟體產生PDF。\n\t\t\t\t"
            ),
          ]),
          _vm._v(" "),
          _c("p"),
          _vm._v(" "),
          _c("h5", [
            _vm._v(
              "24.請問申請入學的審查資料上傳，經確認後還可以補件或抽換嗎？"
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "qa-text" }, [
            _c("img", {
              attrs: { height: "25", src: _vm.content.qa, width: "25" },
            }),
            _vm._v(
              "審查資料於上傳系統確認送出後，如有補件、抽換等需求，請依各大學規定辦理，並請逕洽各報考大學。\n\t\t\t\t\t"
            ),
            _c("br"),
            _vm._v(
              "(1)\t以113學年度為例，依113學年度大學申請入學招生簡章規定：「上傳之審查資料於確認前皆可重複上傳(或重新勾選)，亦即考生若欲修改資料內容時，可將修改後之檔案重新上傳(或自學習歷程中央資料庫中重新勾選上傳)。惟若審查資料一經確認後，一律不得以任何理由要求修改，請考生務必審愼檢視上傳之每一大項資料是否完整後再行確認。」爰審查資料一經確認後，一律不得以任何理由要求修改。\n\t\t\t\t\t"
            ),
            _c("br"),
            _vm._v(
              "(2)\t依113學年度大學申請入學招生簡章規定：「甄選委員會於各校系規定繳交截止日後，逕將考生已完成上傳確認之審查資料及曾上傳(勾選)但未確認之審查資料轉送各大學。考生得否補件、抽換審查資料或參加第二階段指定項目甄試，依各大學規定辦理，考生不得異議。」爰考生得否補件、抽換審查資料或參加第二階段指定項目甄試，依各大學規定辦理。\n\t\t\t\t"
            ),
          ]),
          _vm._v(" "),
          _c("p"),
          _vm._v(" "),
          _c("h5", [
            _vm._v(
              "25.我是技術型高中專業群科學生，想要繳交包含高三之學習歷程檔案，要如何繳交呢？"
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "qa-text" }, [
            _c("img", {
              attrs: { height: "25", src: _vm.content.qa, width: "25" },
            }),
            _vm._v(
              "依教育部國民及學前教育署所定學習歷程檔案提交至學習歷程中央資料庫時程規劃，「專業群」班別(範疇包括各專業群科、綜合高中專門學程、實用技能學程)之應屆畢業生，其高三「課程學習成果」、「多元表現」之學習歷程檔案於114年5月底才完成提交，故此類考生審查資料繳交方式，若選擇「勾選上傳」者，僅提供檢視及勾選第一至第四學期之學習歷程檔案；若欲繳交包含高三之學習歷程檔案者，則須選擇繳交方式為「PDF上傳」，由考生自行將第一至第六學期之學習歷程檔案製作成PDF檔案上傳。\n\t\t\t\t"
            ),
          ]),
          _vm._v(" "),
          _c("p"),
          _vm._v(" "),
          _c("h5", [
            _vm._v(
              "26.大學審查審查資料行之已久，各大學校系有沒有審查時共通的原則呢？"
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "qa-text" }, [
            _c("img", {
              attrs: { height: "25", src: _vm.content.qa, width: "25" },
            }),
            _vm._v(
              "招聯會已彙整出的「大學針對申請入學備審資料審查參考原則」有「三重二不」原則，分別是「重視基本素養所展現的核心能力」、「重視校內的學習活動」、「重視資料真實性及學生自主準備」、「不是學系所列的所有項次都要具備，大學重視多面向的參採」、以及「不是以量取勝，重視學習過程的反思」，不會因為高中開課差異、都市偏鄉資源的不同，就影響到審查的公平性，反而透過學習歷程檔案更能參酌學生的實際情況。\n\t\t\t\t"
            ),
          ]),
          _vm._v(" "),
          _c("p"),
          _vm._v(" "),
          _c("h5", [_vm._v("27.課程學習成果怎麼呈現？大學校系要看幾件呢？")]),
          _vm._v(" "),
          _c("div", { staticClass: "qa-text" }, [
            _c("img", {
              attrs: { height: "25", src: _vm.content.qa, width: "25" },
            }),
            _vm._v(
              "在申請入學招生簡章中，很淸楚載明課程學習成果至多3件，大學更重視學習成果的發展歷程(怎麼做的)、心得(學到什麼)及反思，因此，不一定要交滿3件，有好的1件就足夠了。\n\t\t\t\t\t"
            ),
            _c("br"),
            _vm._v(
              "(1)\t自111學年度起，審查資料可以從學習歷程檔案產出，大學從審查資料中看到學生高中階段的學習活動、學習動機以及人格特質。大學不是逐項給分，而是綜合性審查，從多項資料中對學系需要的能力與特質進行評分。\n\t\t\t\t\t"
            ),
            _c("br"),
            _vm._v(
              "(2)\t課程學習成果包括高中課程的作業、作品等其他學習成果。教育部委託國立臺灣大學社會學系辦理「作伙學：學習歷程檔案審議計畫」，相關審議成果(例如課程學習成果的作品呈現六大指引，以及不同類型作品在製作時的注意事項)等精彩回饋已公告在"
            ),
            _c(
              "a",
              { attrs: { href: "https://www.108epo.com/", target: "_blank" } },
              [_vm._v("作伙學官網")]
            ),
            _vm._v(
              "，另「作伙做學檔-課程學習成果呈現建議」手冊電子書也可至官網的「成果展示」下載參考。\n\t\t\t\t"
            ),
          ]),
          _vm._v(" "),
          _c("p"),
          _vm._v(" "),
          _c("h5", [
            _vm._v("28.參加大學校系的營隊，對於申請大學校系有沒有幫助？"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "qa-text" }, [
            _c("img", {
              attrs: { height: "25", src: _vm.content.qa, width: "25" },
            }),
            _vm._v(" "),
            _c("br"),
            _vm._v(
              "(1)\t參加營隊只能視為興趣探索及瞭解學群學系特色的方式之一。如果您想瞭解自己的興趣或性向，也可以先求助高中或其他輔導資源，藉由性向測驗獲得未來選填志願之方向。\n\t\t\t\t\t"
            ),
            _c("br"),
            _vm._v(
              "(2)\t各大學可積極協助高中學生進行興趣探索及瞭解學系特色，但大學若在辦理相關營隊或活動時，明示或暗示高中同學「付費」參加這項營隊或活動，就可以拿到「參與證明」當作學習歷程檔案，或是學會如何製作大學喜歡的備審資料，將來在參加申請入學準備或放入備審資料時，會有利於獲得大學靑睞，這樣不僅會造成大學自己審查自己收費所核發或指導的資料，衍生嚴重「招生倫理」問題，更是違背各大學向來揭示「三重二不：備審資料應重視校內活動」的原則，反而變相吸引或鼓勵學生參加校外營隊或活動，讓備審資料淪為學生比拼資源、蒐集證書的軍備競賽。\n\t\t\t\t\t"
            ),
            _c("br"),
            _vm._v(
              "(3)\t教育部已函示各大學辦理營隊或活動時，應避免過度連結招生選才或學習歷程檔案等情事，並以非營利、鼓勵參與方式進行，如涉及學習歷程檔案之準備或充實，不得收費，亦不可授予參與證明，以維護招生倫理及招生公信。\n\t\t\t\t\t"
            ),
            _c("br"),
            _vm._v(
              "(4)\t綜上，建議您如擬參加大學營隊或活動，得以興趣探索或瞭解學系特色為出發點；避免誤以為有助於申請大學校系而支付高額費用參與大學營隊或活動。\n\t\t\t\t"
            ),
          ]),
          _vm._v(" "),
          _c("p"),
          _vm._v(" "),
          _c("h5", [
            _vm._v(
              "29.我看到電商平臺刊登販售學習歷程檔案諮詢、代寫商品，且強調由大學教授等審查委員專業認證，真的有助於申請入學嗎？"
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "qa-text" }, [
            _c("img", {
              attrs: { height: "25", src: _vm.content.qa, width: "25" },
            }),
            _vm._v("沒有幫助。\n\t\t\t\t\t"),
            _c("br"),
            _vm._v(
              "請勿聽信購買電商平臺販售大學招生備審資料(包含學習歷程檔案)諮詢、代寫，以及強調由大學教授等審查委員專業認證等涉虛偽不實之商品，以免受騙上當，說明如下：\n\t\t\t\t\t"
            ),
            _c("br"),
            _vm._v(
              "(1)\t依大學申請入學招生簡章規定，如考生所繳證件或資料有假借、冒用、偽造、變造或考試舞弊等情事，大學將依規定取消錄取資格、開除學籍或撤銷學位證書及畢業資格，情節重大者函送司法單位審理。\n\t\t\t\t\t"
            ),
            _c("br"),
            _vm._v(
              "(2)\t鑑於電商平臺刊登銷售備審資料(包含學習歷程檔案)代寫代做之相關服務商品內容，已涉嫌引導考生違反大學法及大學申請入學招生規定；為遏止前述情事，教育部已函請數位發展部評估協助轉知各電商平臺本於社會責任，妥為管理電商平臺刊登相關商品；數位發展部也已函請電商平臺相關公(協)會或公司善盡審查責任並協助宣導所屬會員，不得於電商平臺上架登載有關學習歷程檔案代寫代做相關服務或商品內容，並落實商品上架查核機制，以避免違法商品及服務上架販售。\n\t\t\t\t\t"
            ),
            _c("br"),
            _vm._v(
              "(3)\t學生於各教育階段學習之課程內涵及其所獲得之能力，均有其眞實存在，因此，招聯會公布「大學針對申請入學備審資料審查參考原則」中，亦強調「重視資料眞實性及學生自主準備」，代寫備審資料(包含學習歷程檔案)不是學生本人自主準備，不屬於學生的眞實能力。大學透過招生專業審查，可以詳實辨別學生備審資料之眞實性，以及學生的獨特性、素養能力、學習過程等。\n\t\t\t\t\t"
            ),
            _c("br"),
            _vm._v(
              "(4)\t教育部已要求大學教職員不得以個人名義參與或兼職有關營利性質且涉及學習歷程檔案準備或充實之營隊、課程或諮詢工作室等相關活動，藉以收取報酬；並要求大學辦理招生應遵守招生倫理，當年度招生試務或甄試委員亦應落實迴避原則。\n\t\t\t\t"
            ),
          ]),
          _vm._v(" "),
          _c("p"),
          _vm._v(" "),
          _c("h5", [
            _vm._v(
              "30.我想利用AI工具(聊天機器人)幫我製作申請入學第二階段備審資料，大學教授如何審查呢？"
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "qa-text" }, [
            _c("img", {
              attrs: { height: "25", src: _vm.content.qa, width: "25" },
            }),
            _vm._v(
              "大學在審查資料時相當注重資料眞實性與考生自主準備，考生不宜過度依賴AI工具，若讓審查教授對於審查資料產生抄襲或代寫疑慮，對考生可能有不利的影響。招聯會訂定「因應AI技術發展，大學辦理申請入學招生參考注意事項」，提醒各校系辦理申請入學招生時留意：\n\t\t\t\t\t"
            ),
            _c("br"),
            _vm._v(
              "(1)\t重申「三重二不」原則：學系審查時，重視的是學生基本素養所展現的核心能力、學生在校內的學習活動、資料眞實性及學生自主準備，學系應秉持前開原則嚴謹、妥善辦理。\n\t\t\t\t\t"
            ),
            _c("br"),
            _vm._v(
              "(2)\t掌握審查資料重點：審查資料的目的，是透過學生呈現的資料，了解學生高中三年所參與的學習活動與成果，藉此評估其所展現的學習動機、興趣、能力及特質是否適合學系。學系應綜合評量，而非僅以文字表述能力為評分依據。\n\t\t\t\t\t"
            ),
            _c("br"),
            _vm._v(
              "(3)\t加強其他甄試項目：各校系可透過既有的面試或實作等其他甄試項目確認資料眞實性，且更多面向的了解學生特質與能力，各校系可多加留意學生審查資料內容與其他甄試項目表現間之連結。目前於第二階段指定項目僅辦理審查資料之校系，未來可加強規劃辦理其他甄試項目。\n\t\t\t\t"
            ),
          ]),
          _vm._v(" "),
          _c("p"),
          _vm._v(" "),
          _c("h5", [
            _vm._v(
              "31.我在社群軟體(例如：FB或IG)的升學社團中看到網友討論大學考試、招生的訊息，要怎麼確認是正確的資訊呢？"
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "qa-text" }, [
            _c("img", {
              attrs: { height: "25", src: _vm.content.qa, width: "25" },
            }),
            _vm._v(" "),
            _c("br"),
            _vm._v(
              "(一)\t大學招生自主，各考招單位及教育部委託計畫團隊分別針對不同受眾及需求辦理相關活動，期以多元形式傳遞正確的升學資訊，以利推動多元入學方案及社會溝通；學生及家長除透過就讀高中所提供的升學相關資訊外，亦得透過參與考招單位辦理的實體活動、宣導文宣手冊、考招單位官方網站及宣導影片等不同方式獲取正確資訊。\n\t\t\t\t\t"
            ),
            _c("br"),
            _vm._v("(二)\t現行相關宣導作法舉例如下：\n\t\t\t\t\t"),
            _c("br"),
            _vm._v(
              "(1)\t招聯會會同教育部每年辦理大學多元入學方案全國家長/學生分區說明會(北中南東4區)，相關內容並置於網頁供學生家長參考，您可以上招聯會網站留意活動訊息。\n\t\t\t\t\t"
            ),
            _c("br"),
            _vm._v("(2)\t持續維護並滾動更新"),
            _c(
              "a",
              {
                attrs: { href: "https://nsdua.moe.edu.tw/", target: "_blank" },
              },
              [_vm._v("「大學多元入學升學網」")]
            ),
            _vm._v(
              "，教育部每年編印「新一學年度大學多元入學手冊」發送各高中，供學生家長查閱升學資訊。透過招聯會官方網站發布多元入學方案、考招措施相關新聞稿、宣導摺頁、動畫影片與常見問答等宣導資訊。\n\t\t\t\t\t"
            ),
            _c("br"),
            _vm._v("(3)\t各項考試相關資訊均於"),
            _c(
              "a",
              { attrs: { href: "https://www.ceec.edu.tw/", target: "_blank" } },
              [_vm._v("大考中心官網")]
            ),
            _vm._v(
              "公吿供學生參考，大考中心line官方帳號於108年10月15日正式上線，內容包含考試說明、報名事項、報名文件、報名繳費、應考事項、違規事項、常見違規、考前叮嚀、大學多元入學諮詢管道等主題選單，學生得多加利用。\n \t\t\t\t\t"
            ),
            _c("br"),
            _c(
              "a",
              {
                attrs: {
                  target: "_blank",
                  href: "https://line.me/R/ti/p/%40415tatkb",
                },
              },
              [
                _c("img", {
                  staticStyle: { width: "100%" },
                  attrs: { src: _vm.content.official_line },
                }),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("p"),
          _vm._v(" "),
          _c("h5", [
            _vm._v(
              "32.請問我不是108 課綱的學生(如重考生、境外返國、高中肄業等)，所以我沒有學習歷程檔案， 111 學年度起還可以參加那些聯合招生管道？是否會影響評分？"
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "qa-text" }, [
            _c("img", {
              attrs: { height: "25", src: _vm.content.qa, width: "25" },
            }),
            _vm._v(
              "申請入學、分發入學招生管道均可報名。另可以參加各校單獨招生的特殊選才管道。沒有學習歷程檔案是否會影響評分，就各管道分別說明如下：\n\t\t\t\t\t"
            ),
            _c("br"),
            _vm._v(
              "(1)\t申請入學：自 111 學年度起，考生通過第一階段篩選之後，仍可以在申請大學校系時到甄選會網站以PDF檔案方式上傳審查資料。至於審查資料之審查，則由大學依其學術專業及系所選才需求進行審查，如學生有不同教育資歷亦會審酌給予公平、公正之評分。\n\t\t\t\t\t"
            ),
            _c("br"),
            _vm._v(
              "(2)\t分發入學：僅採計入學考試成績後直接分發，與學習歷程檔案無涉。\n\t\t\t\t"
            ),
          ]),
          _vm._v(" "),
          _c("p"),
          _vm._v(" "),
          _c("h5", [
            _vm._v(
              "33.若我有通過申請入學任一校系的第1階段篩選，如何繳交校系規定之審查資料？可直接郵寄紙本給大學嗎？"
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "qa-text" }, [
            _c("img", {
              attrs: { height: "25", src: _vm.content.qa, width: "25" },
            }),
            _vm._v(
              "111學年度起，校系如有要求課程學習成果、多元表現之審查項目，考生可自學習歷程中央資料庫中勾選資料檔案後上傳，或自行製作PDF格式檔案後上傳，每一校系「勾選上傳」或「PDF上傳」僅限擇一方式辦理；考生若於學習歷程中央資料庫無資料檔案者，皆以「PDF上傳」方式辦理。\n\t\t\t\t\t"
            ),
            _c("br"),
            _vm._v(
              "依簡章規定各大學規定繳交之審查資料，除大學校系另有規定外，一律以網路上傳(勾選)方式繳交至甄選會。\n\t\t\t\t\t"
            ),
            _c("br"),
            _vm._v(
              "部分校系針對若干審查資料項目，另訂以郵寄或其他方式繳交者，考生應依其規定另行繳交至該大學；為避免自身權益受損，請務必詳閱該校系分則規定。\n\t\t\t\t"
            ),
          ]),
          _vm._v(" "),
          _c("p"),
          _vm._v(" "),
          _c("h5", [
            _vm._v(
              "34.我已通過申請入學第1階段篩選，在參加第2階段指定項目甄試時，大學審查教授可以看到學測成績嗎？ "
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "qa-text" }, [
            _c("img", {
              attrs: { height: "25", src: _vm.content.qa, width: "25" },
            }),
            _vm._v("不可以。\n\t\t\t\t\t"),
            _c("br"),
            _vm._v(
              "99學年度起，教育部要求各校不提供各學系參加第2階段甄試考生之學測成績，所以在通過第一階段篩選後，進入第二階段指定項目甄試時，大學審查教授無法看到學測成績，僅能針對考生之審查資料或面試等甄試項目評分，考生的甄試總成績是由各校招生單位，依據各系組在招生簡章的校系分則裡所列的成績佔分比例來計算。\n\t\t\t\t"
            ),
          ]),
          _vm._v(" "),
          _c("p"),
          _vm._v(" "),
          _c("h5", [
            _vm._v(
              "35.填寫「申請入學」志願時，如果我將備取系組填在前面，正取系組填在後面，而備取沒有獲分發，是否正取系組也無法獲分發？"
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "qa-text" }, [
            _c("img", {
              attrs: { height: "25", src: _vm.content.qa, width: "25" },
            }),
            _vm._v("不會。\n\t\t\t\t\t"),
            _c("br"),
            _vm._v(
              "填寫志願序時，無論其為正取或備取及其備取序，請依照您自己最想就讀校系的順序填寫，備取的志願校系如果有正取生放棄時，就有機會遞補。如果沒有備取到，仍可分發到正取系組。另提醒您，正取系組志願之後的其他志願序，都是無效的志願序。\n\t\t\t\t"
            ),
          ]),
          _vm._v(" "),
          _c("p"),
          _vm._v(" "),
          _c("h5", [
            _vm._v("36.非官方之落點分析可靠嗎？學生需要去做落點分析嗎？"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "qa-text" }, [
            _c("img", {
              attrs: { height: "25", src: _vm.content.qa, width: "25" },
            }),
            _vm._v(
              "落點分析是使用去年校系的錄取分數推估今年校系的錄取分數。但是每年均有部分校系採計之考科數、加重計分方式改變或考生成績分布不同、校系名額異動等差異，落點分析一定會有誤差，不可全信，僅可作為參考。\n\t\t\t\t"
            ),
          ]),
          _vm._v(" "),
          _c("p"),
          _vm._v(" "),
          _c("h5", [
            _vm._v(
              "37.各招生管道前一學年度之各系所的錄取級分及篩選科目或加權計分等規定，我該去何處查詢？"
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "qa-text" }, [
            _c("img", {
              attrs: { height: "25", src: _vm.content.qa, width: "25" },
            }),
            _vm._v("(1)繁星推薦：請至"),
            _c(
              "a",
              { attrs: { href: "https://www.cac.edu.tw", target: "_blank" } },
              [_vm._v("甄選會網站")]
            ),
            _vm._v(
              "選擇「繁星推薦」進入「歷年資料」點選欲查詢學年度查看「各校系錄取標準一覽表」。\n\t\t\t\t\t"
            ),
            _c("br"),
            _vm._v("(2)申請入學：請至"),
            _c(
              "a",
              { attrs: { href: "https://www.cac.edu.tw", target: "_blank" } },
              [_vm._v("甄選會網站")]
            ),
            _vm._v(
              "選擇「申請入學」進入「歷年資料」點選欲查詢學年度查看「各校系篩選標準一覽表」及「各校系最低分發標準一覽表」。\n\t\t\t\t\t"
            ),
            _c("br"),
            _vm._v("(3)分發入學：請至"),
            _c(
              "a",
              { attrs: { href: "https://www.uac.edu.tw", target: "_blank" } },
              [_vm._v("考分會網站")]
            ),
            _vm._v(
              "選擇「下載專區」進入「歷年資料下載」點選年度下載「各系組最低錄取分數及錄取人數一覽表」。\n\t\t\t\t"
            ),
          ]),
          _vm._v(" "),
          _c("p"),
          _vm._v(" "),
          _c("h5", [
            _vm._v("38.我家中沒有電腦，該如何上傳審查資料及上網選填志願？"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "qa-text" }, [
            _c("img", {
              attrs: { height: "25", src: _vm.content.qa, width: "25" },
            }),
            _vm._v(
              "各高中均開放部分電腦及網路設備提供畢業考生上網上傳審查資料或選填志願，家中沒有電腦或網路的考生可至畢業學校尋求協助。此外，也可到各鄉(鎮、市、區)公所或公立圖書館使用資訊網路設備。\n\t\t\t\t"
            ),
          ]),
          _vm._v(" "),
          _c("p"),
          _vm._v(" "),
          _c("h5", [
            _vm._v(
              "39.繁星推薦及申請入學管道中某些校系規定不得轉系，可以考轉學考嗎？"
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "qa-text" }, [
            _c("img", {
              attrs: { height: "25", src: _vm.content.qa, width: "25" },
            }),
            _vm._v(
              "繁星推薦及申請入學之錄取生就讀大學期間得否轉系由各大學自行訂定，並在當年度招生簡章中敘明，考生因學習需要得自行參加轉學考試。\n\t\t\t\t"
            ),
          ]),
          _vm._v(" "),
          _c("p"),
          _vm._v(" "),
          _c("h5", [_vm._v("40.哪些人需要參加分發入學「文件審查」？")]),
          _vm._v(" "),
          _c("div", { staticClass: "qa-text" }, [
            _c("img", {
              attrs: { height: "25", src: _vm.content.qa, width: "25" },
            }),
            _vm._v(
              "(1)未通過「93 至 113 學年度學力證明文件審查」，且非學校集體報名之應屆生，須繳交學歷(力)證明文件進行審查，審驗未通過者，不得參與登記分發。\n\t\t\t\t\t"
            ),
            _c("br"),
            _vm._v(
              "(2)以特種生身分登記者，須提出特種生身分審查申請，逾期未提出申請或經審查為資格不符者，視為普通生不予加分優待。(特種生類別包含：原住民、退伍軍人、僑生、蒙藏生、派外人員子女、境外優秀科技人才子女)\n\t\t\t\t\t"
            ),
            _c("br"),
            _vm._v(
              "(3)聽覺障礙中、重度學生，須繳交身心障礙證明或符合規定之診斷書，經審驗通過者，參與分發時得免檢定英語聽力測驗成績。\n\t\t\t\t"
            ),
          ]),
          _vm._v(" "),
          _c("p"),
          _vm._v(" "),
          _c("h5", [
            _vm._v(
              "41.持大陸地區高中學歷(力)證件的學生，參加分發入學「文件審查」時應該繳交什麼樣的文件？"
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "qa-text" }, [
            _c("img", {
              attrs: { height: "25", src: _vm.content.qa, width: "25" },
            }),
            _vm._v(
              "大陸地區的高中學歷(力)證件(如畢業證(明)書)，須經大陸公證處公證及財團法人海峽交流基金會驗證，才能參加登記。如已完成前開程序，審查時僅需繳交高中學歷(力)證明(如畢業證書)即可，如為應屆畢業生尚未拿到畢業證書，則可先繳交歷年成績單，並附上切結書，保證將拿到畢業證書並完成前開程序。\n\t\t\t\t"
            ),
          ]),
          _vm._v(" "),
          _c("p"),
          _vm._v(" "),
          _c("h5", [
            _vm._v(
              "42.持國外或港澳地區高中學歷(力)證件的學生，參加分發入學學力審查時應該繳交什麼樣的文件？ "
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "qa-text" }, [
            _c("img", {
              attrs: { height: "25", src: _vm.content.qa, width: "25" },
            }),
            _vm._v(
              "持國外或港澳地區高中學歷(力)學生，須繳交經我國駐外館處驗證之高中學歷(力) 證明(如畢業證書)及歷年成績單，並提供歷年入出境日期證明及護照(中英文姓名資料頁)作為查證之用。如為應屆畢業生尚未拿到畢業證書及最後一學期成績單者，可先繳交除最後一學期外之歷年成績單及高三在學證明，並附上切結書。\n\t\t\t\t"
            ),
          ]),
          _vm._v(" "),
          _c("p"),
          _vm._v(" "),
          _c("h5", [
            _vm._v("43.參加分發入學完成登記志願後，是否可再申請更改？"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "qa-text" }, [
            _c("img", {
              attrs: { height: "25", src: _vm.content.qa, width: "25" },
            }),
            _vm._v(
              "不可以，登記志願以一次為限，即使尚在登記期間亦不可再更改志願，請務必仔細核對志願無誤後再確認送出。\n\t\t\t\t"
            ),
          ]),
          _vm._v(" "),
          _c("p"),
          _vm._v(" "),
          _c("h5", [_vm._v("44.大學分發入學落榜後，還有其他升學管道嗎？")]),
          _vm._v(" "),
          _c("div", { staticClass: "qa-text" }, [
            _c("img", {
              attrs: { height: "25", src: _vm.content.qa, width: "25" },
            }),
            _vm._v(
              "我國大學入學管道多元，除繁星推薦、申請入學及分發入學三大聯合招生管道之外，尚有單獨招生管道可升讀大學，由學校自主規劃筆試、面試、書面審查、術科或實作測驗等方式甄選，免受學測或分科測驗考試成績限制，單獨招生管道如日間學士班單獨招生、大學進修學士班、空中大學。\n\t\t\t\t"
            ),
          ]),
          _vm._v(" "),
          _c("p"),
          _vm._v(" "),
          _c("h5", [
            _vm._v(
              "45.持大陸地區高中學歷(力)證件的學生，入學時應該繳交什麼樣的文件？"
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "qa-text" }, [
            _c("img", {
              attrs: { height: "25", src: _vm.content.qa, width: "25" },
            }),
            _vm._v(
              "大陸地區的高中學歷，須經大陸公證處公證、財團法人海峽交流基金會驗證，由直轄市、縣市政府行政機關辦理採認或由就讀學校辦理查驗後，送教育部國教署辦理查證及認定，始完成大陸地區學歷採認程序。\n\t\t\t\t\t"
            ),
            _c("br"),
            _vm._v(
              "(1)如入學前已完成學歷採認程序者(即學歷已送經直轄市、縣市政府行政機關辦理採認者)：需繳交高中學歷(力)證明(如畢業證書)及教育主管行政機關採認函文影本至就讀學校，就讀學校得直接受理即可，無須再送教育部國教署查驗及認定。\n\t\t\t\t\t"
            ),
            _c("br"),
            _vm._v(
              "(2)如入學前尚未完成學歷採認者：請於入學前先完成大陸公證處公證及財團法人海峽交流基金會驗證程序後，需繳交高中學歷(力) 證件(如畢業證書)至就讀學校，由就讀大學辦理查驗後，送教育部國教署辦理查驗及認定。\n\t\t\t\t"
            ),
          ]),
          _vm._v(" "),
          _c("p"),
          _vm._v(" "),
          _c("h5", [
            _vm._v(
              "46.持國外或港澳地區高中學歷(力)證件的學生，入學時應該繳交什麼樣的文件？ "
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "qa-text" }, [
            _c("img", {
              attrs: { height: "25", src: _vm.content.qa, width: "25" },
            }),
            _vm._v(
              "持國外或港澳地區高中學歷(力)學生，須繳交經我國駐外館處驗證之高中學歷(力) 證明(如畢業證書) 及歷年成績單，並提供歷年入出境日期證明及護照(中英文姓名資料頁)作為查證之用，及大學規定之其他相關文件。若學生係外國人或僑民者，免附入出境證明。\n\t\t\t\t"
            ),
          ]),
          _vm._v(" "),
          _c("p"),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }