import components from 'common/component'
import db from 'common/db'
import Vue from 'vue'
import Vuex from 'vuex'
import VueRouter from 'vue-router'
import Vuetify from 'vuetify'
import 'page/_index/-index.scss'

Vue.use( Vuex )
Vue.use( VueRouter )
Vue.use( Vuetify )

// disable warnings of Vue
Vue.config.productionTip = false
Vue.config.devtools = false

// register common components
for (const [name, option] of Object.entries(components)) {
    Vue.component(name, option)
}

let vue = {}

;( async () => {

// initialize indexedDB
await db.init()

// initialize vue app
vue = new Vue({
    el: 'v-app',
    data:       require('page/_index/data.js').default,
    methods:    require('page/_index/method.js').default,
    /*hook*/ ...require('page/_index/hook.js').default,
    router: new VueRouter({
        routes: require('common/route').default,
        scrollBehavior () {
            $('html, body').scrollTop(0);
            return { x: 0, y: 0 }
        }
    }),
    store: new Vuex.Store({
             ...require('common/store').default,
    }),
})

})()

export default vue
