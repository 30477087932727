var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      attrs: {
        "close-on-content-click": false,
        "nudge-width": 200,
        "offset-x": "",
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function (ref) {
            var on = ref.on
            return [
              _c(
                "v-btn",
                _vm._g({ attrs: { color: "indigo", dark: "" } }, on),
                [
                  _vm._v(
                    "\r\n        " + _vm._s(_vm.user || "訪客") + "\r\n        "
                  ),
                ]
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.open,
        callback: function ($$v) {
          _vm.open = $$v
        },
        expression: "open",
      },
    },
    [
      _vm._v(" "),
      _c(
        "v-card",
        [
          _c(
            "v-list",
            [
              _c(
                "v-list-tile",
                { attrs: { avatar: "" } },
                [
                  _c("v-list-tile-avatar", [
                    _c("img", {
                      attrs: {
                        src: "https://cdn.vuetifyjs.com/images/john.jpg",
                        alt: "",
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-list-tile-content",
                    [
                      _c("v-list-tile-title", [
                        _vm._v(_vm._s(_vm.user || "訪客")),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("v-divider"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }