<template>
    <div class="page_admin">
        <v-container fluid fill-height>
            <v-layout align-center justify-center>
                <v-flex xs12 sm8 md4>
                    <v-card class="elevation-12">
                        <v-toolbar dark color="primary">
                            <v-toolbar-title>登入</v-toolbar-title>
                            <v-spacer></v-spacer>
                        </v-toolbar>
                        <v-card-text>
                            <v-form ref="form">
                                <v-text-field
                                    prepend-icon="person"
                                    label="帳號"
                                    type="text"
                                    v-model="account"
                                    :rules="[ 
                                        v => !!v || '請輸入帳號', 
                                        v => /^[a-z]+$/.test(v) || '帳號格式有誤', 
                                    ]"
                                    required
                                    autofocus
                                ></v-text-field>
                                <v-text-field
                                    prepend-icon="lock"
                                    label="密碼"
                                    type="password"
                                    v-model="password"
                                    :rules="[ 
                                        v => !!v || '請輸入密碼', 
                                        v => /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/.test(v) || '密碼格式有誤', 
                                    ]"
                                    required
                                ></v-text-field>
                            </v-form>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <span class="red--text">{{ msg }}</span>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" @click="login">登入</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-flex>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
export default {
    data() {
        return {
            account: '',
            password: '',
            msg: '',
        }
    },
    methods: {
        async login() {

            if(this.$refs.form.validate()) {
                const res = await ___.api.user.login({
                    account: this.account,
                    password: this.password,
                })
                if      (this.allow_ips.indexOf(this.userIP) == -1)  this.msg = 'D04:登入失敗' //'IP不允許'
                else if (res.msg == 'successed')        this.$router.replace('/admin')
                else if (res.msg == 'invalid')          this.msg = 'D01:登入失敗' //'密碼錯誤'
                else if (res.msg == 'user not found!')  this.msg = 'D02:登入失敗' //'無此帳號'
                else if (res.msg == 'invalid:lock')     this.msg = 'D05:登入失敗:目前帳號鎖定15分鐘' //'密碼錯誤'
                else                                    this.msg = 'D03:登入失敗' //'不明原因錯誤'
            }
        },
    },
    async created() {

        this.userIP = await fetch('https://api.ipify.org?format=json').then(response => response.json()).then(response => response.ip)

        //check user IP
        this.allow_ips = ['140.116.221.188', '140.116.221.176', '140.116.205.35', '140.116.205.37']

        if(this.allow_ips.indexOf(this.userIP) == -1) this.$router.replace('/')
    },
}

</script>

<style lang="scss">
@import "~common/base";
.page_admin {
    .v-card {
        margin-top: 100px;
    }
}
</style>
