import vue from 'page/_index/-index'
import api from 'common/api'
import cookie from 'util/cookie'
import mergeWith from 'lodash.mergewith'

// ====================== define data schema here ======================
const userData = { // user settings
    firstUse: true,
    lang: navigator.language == 'en' ? 'en' : 'cht',
}
const appData = { // cached app data
    userInfo: {},
}
// ===================================================================

let db = {}
let ud = {}
let ad = {}

export default {
    getDB: () => db,
    getUserData: () => ud,
    getAppData: () => ad,
    init: () => {
        return new Promise( resolve => {
            const requestDB = window.indexedDB.open('nsdua')
            let first = false
            // first use of this app (initialize indexedDB)
            requestDB.onupgradeneeded = (e) => {
                const db = e.target.result
                const user = db.createObjectStore('general', { autoIncrement: true })
                user.add(userData)
                user.add(appData)
                first = true
            }
            // get user data from indexedDB
            requestDB.onsuccess = () => {
                db = requestDB.result
                const transaction = db.transaction(['general'], 'readwrite')
                const request = transaction.objectStore('general').openCursor()
                let result = []
                let data = {}
                request.onsuccess = (e) => {
                    const cursor = e.target.result
                    if (cursor) {
                        // merge schema
                        if      (cursor.key == 1){ data = mergeWith({}, userData, cursor.value); if (!first) delete data.firstUse }
                        else if (cursor.key == 2)  data = mergeWith({}, appData,  cursor.value)
                        result.push(data)
                        cursor.update(data)
                        cursor.continue()
                    }
                    else {
                        ud = result[0]
                        ad = result[1]
                        resolve(result)
                    }
                    
                }
            }
        })
    },
    userData: {
        set: (data) => setData(data, 1),
        get: () => getData(1),
        sync: (data) => Promise.all([
            cookie.getCookie('account') ? api.user.setData(data) : 'Not logged in',
            setData(data, 1),
            vue.userData = { ...vue.userData, ...data },
        ]),
    },
    appData: {
        set: (data) => setData(data, 2),
        get: () => getData(2),
    },
}

function setData(data, id, useFrameworkData = true) {
    return new Promise( resolve => {
        // use framework data
        if (useFrameworkData) {
            const transaction = db.transaction(['general'], 'readwrite')
            const target = id == 1 ? vue.userData : vue.appData
            const request = transaction.objectStore('general').put({ ...target, ...data }, id)
            request.onsuccess = () => resolve('successed')
        }
        // find data from database
        else {
            const transaction = db.transaction(['general'], 'readwrite')
            const request = model.user.objectStore('general').get(id)
            request.onsuccess = () => {
                const request = transaction.objectStore('general').put({ ...request.result, ...data }, id)
                request.onsuccess = () => resolve('successed')
            }
        }
    })
}

function getData(id) {
    return new Promise( resolve => {
        const transaction = db.transaction(['general'], 'readwrite')
        const request = transaction.objectStore('general').get(id)
        request.onsuccess = () => resolve(request.result)
    })
}
