export default [
{
    path: '/', component: require('page/_home/frame.vue').default,
    children: [
        { path: '',         component: require('page/_home/news.vue').default },
		{ path: 'qa',         component: require('page/_home/qa.vue').default },
		{ path: 'qa1',         component: require('page/_home/qa1.vue').default },
		{ path: 'qa2',         component: require('page/_home/qa2.vue').default },
		{ path: 'qa3',         component: require('page/_home/qa3.vue').default },
		{ path: 'service1',         component: require('page/_home/service1.vue').default },
        { path: 'download', component: require('page/_home/download.vue').default },
        { path: 'other',    component: require('page/_home/other.vue').default,
            alias: [
                'about',
                'examinations',
                'examinations/exam-1',
                'examinations/exam-2',
                'examinations/exam-3',
                'examinations/exam-4',
                'admissions',
                'admissions/admission-5',
                'admissions/admission-4',
                'admissions/admission-3',
                'admissions/admission-2',
                'admissions/admission-1',
                'relations',
                'schedule',
                'revision',
                'revision/revision-2',
                'revision/revision-1',
                'srecruit-1',
                'collego',
                'note',
                'plan',
                'flowchart',
                'agency',
                'calendar',
                'news',
                'service',
                'faq',
                'faq/faq-1',
                'faq/faq-2',
                'faq/faq-3',
            ],
        },
    ]
},
{   path: '/login', component: require('page/login.vue').default },
{
    path: '/admin', redirect: '/admin/pending', component: require('page/_admin/frame.vue').default,
    children: [
        { path: 'pending', alias: ['published', 'deleted'], component: require('page/_admin/news_manager.vue').default, },
        { path: 'add-news', component: require('page/_admin/add_news.vue').default },
        { path: 'update-user', component: require('page/_admin/update_user.vue').default },
    ],
},
]
