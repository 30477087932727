<template>
	<div class="page_home_download col-lg-8 mb-8">
		<!-- title -->
		<v-chip class="font-size:3rem;" text-color="white" color="primary">常見問題</v-chip>
		<br><br>
		<div class="card mb-4">
			<div class="card-body reminder">
				<h2 class="card-title">諮詢</h2>
				<p class="card-text"></p>
				
				<p></p>
				<h5>47.什麼是ColleGo!系統？有哪些功能呢？</h5>
				<div class="qa-text">
					<img height="25" :src="content.qa" width="25" />(1) ColleGo! 取自英文College(大學)與Go(前往)組合而成，鼓勵探索、適性、前進。
					<br>(2) ColleGo! 提供「大學選才」、「高中育才」與「焦點話題」三個類別的資訊，內容豐富，特別設計了閱讀資料時需要的各項功能，包括比較功能、搜尋功能及個人化功能等。不論是高一、高二或高三學生，若不熟悉大學校系，也不知道如何選擇校系，ColleGo! 是可以幫上忙的。
				</div>

				<p></p>
				<h5>48.學群、學類、學系是什麼？ </h5>
				<div class="qa-text">
					<img height="25" :src="content.qa" width="25" />ColleGo!可查詢近2000個大學校系，但校系數眾多查詢不易，因此目前ColleGo!取用大考中心之學群學類分類架構，此分類架構依興趣代碼與學習內容，把相近的學系歸為一個學類，將領域相近的學類集結為一個學群。目前本系統已發展132個學類，並計有18個學群，進入ColleGo! 系統，就可以依學群、學類和對應的學系進行查詢與閱讀資訊。
				</div>

				<p></p>
				<h5>49.ColleGo! 「大學選才」中有哪些功能對我認識校系有幫助呢？</h5>
				<div class="qa-text">
					<img height="25" :src="content.qa" width="25" />除了從ColleGo!「大學選才」中的「認識學類」查看「對應校系」之外，還有比較功能和搜尋功能，能幫助你更聚焦在認識校系及選擇校系： 
					<br>(1) 比較功能：可一次比較感興趣的三個學群、學類與學系，迅速掌握不同學群、學類、學系之間差異。
					<br>(2) 搜尋功能：可利用特定關鍵字進行資訊查找，或選擇不同篩選條件進行進階搜尋，取得自己所需要的資料。
				</div>

				<p></p>
				<h5>50.ColleGo!「高中育才」內有什麼內容對我選擇校系有幫助呢？</h5>
				<div class="qa-text">
					<img height="25" :src="content.qa" width="25" />「高中育才」包含五種「探索系列」模組及「三年行動計畫」，其中「探索興趣類型」、「探索領域學科」二個探索模組，以及「三年行動計畫」很適合高三學生使用。
					<br>(1) 探索興趣類型：透過興趣代碼找到可能適合之學群、學類，再從學類往下探索到學系。
					<br>(2) 探索領域學科：選擇自己感興趣或擅長的學科，參考重視該學科的學群、學類，再從學類往下探索到學系。
					<br>(3) 三年行動計畫：選擇高三階段的「升學準備」該項行動類別，裡面有「我的選系決策－學類比較」、「我的選系決策－學系比較」、「所選校系的理由」、「選系放大鏡」等行動能幫助你進行校系選擇。
				</div>

				<p></p>
				<h5>51.	我想知道就讀高中的課程地圖及開設課程，該如何查詢？</h5>
				<div class="qa-text">
					<img height="25" :src="content.qa" width="25" />教育部國民及學前教育署已建置<a href="https://course.k12ea.gov.tw/" target="_blank">「全國高級中等學校課程計畫平臺」</a>羅列各所高中的基本資料、學校特色及課程地圖，讓學生與家長深入了解就讀的高中，協助學生探索生涯發展方向。在課程地圖上可以看到：
					<br>(1)學校針對不同班別，在不同年級的課程規劃。
					<br>(2)不同課程類型(部定必修、校訂必修、選修)開設的課程。
					<br>(3)學校的課程計畫，學校的基本資料、願景與學生圖像、學校特色項目、課程發展與規劃、團體活動時間及彈性學習時間的實施規劃、學校選課輔導流程機制等，以幫助學生認識高中三年的課程規劃。若擬進一步瞭解就讀高中之課程規劃，可直接洽詢就讀高中。
				</div>


				<!-- last -->
				<p></p>
			</div>
		</div>
	</div>
</template>

<script>
export default {
    data() {
        return {
			content: {
                qa: require('page/_home/img/carousel/qa.png'),
                official_line: require('page/_home/img/official_line.png')
            },
        }
    },
}
</script>