<template>
<div class="page_home_download col-lg-8 mb-8">

   <!-- title -->
    <v-chip class="font-size:3rem;" text-color="white" color="primary">諮詢管道</v-chip>
	 <br><br> 
	 <div class="card mb-4">
        <div class="card-body reminder">
          
              <div class="page_home_other"><span tabindex="0" class="v-chip font-size:3rem; theme--light primary white--text">
				</span>
					<div><a href="https://nsdua.moe.edu.tw" target="_blank"><img :src="content.U2_01" width="100%" border="0"></a></div>
					<div><a href="https://collego.edu.tw" target="_blank"><img :src="content.U2_02" width="100%"></a></div>
					<div><a href="https://www.jbcrc.edu.tw" target="_blank"><img :src="content.U2_03" width="100%"></a></div>
					<div><a href="https://www.ceec.edu.tw" target="_blank"><img :src="content.U2_04" width="100%"></a></div>
					<div><a href="https://www.cape.edu.tw" target="_blank"><img :src="content.U2_05" width="100%"></a></div>
					<div><a href="https://www.cac.edu.tw" target="_blank"><img :src="content.U2_06" width="100%"></a></div>
					<div><a href="https://www.uac.edu.tw" target="_blank"><img :src="content.U2_07" width="100%"></a></div>
				<div></div></div>

        </div>
    </div>
   
</div>
</template>

<script>
export default {
    data() {
        return {
			content: {
                U2_01: require('page/_home/img/U2/U2_01.gif'),
                U2_02: require('page/_home/img/U2/U2_02.gif'),
                U2_03: require('page/_home/img/U2/U2_03.gif'),
                U2_04: require('page/_home/img/U2/U2_04.gif'),
                U2_05: require('page/_home/img/U2/U2_05.gif'),
                U2_06: require('page/_home/img/U2/U2_06.gif'),
                U2_07: require('page/_home/img/U2/U2_07.gif')
            },
        }
    },
}
</script>