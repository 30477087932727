var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page_admin_newsManager" },
    [
      _c("v-progress-linear", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.news[0],
            expression: "!news[0]",
          },
        ],
        attrs: { indeterminate: true },
      }),
      _vm._v(" "),
      _c(
        "v-container",
        { attrs: { "fill-height": "" } },
        [
          _c(
            "v-layout",
            { attrs: { "align-center": "" } },
            [
              _c(
                "v-flex",
                [
                  _c(
                    "v-list",
                    { attrs: { "two-line": "" } },
                    [
                      _c(
                        "transition-group",
                        { attrs: { name: "slide" } },
                        _vm._l(_vm.news[_vm.page - 1], function (item, index) {
                          return _c(
                            "v-list-tile",
                            { key: item._id },
                            [
                              _c("v-list-tile-sub-title", [
                                _vm._v(_vm._s(item.date.slice(0, 10))),
                              ]),
                              _vm._v(" "),
                              item.announcer == "ceec"
                                ? _c(
                                    "v-chip",
                                    {
                                      attrs: {
                                        small: "",
                                        label: "",
                                        "text-color": "white",
                                        color: "primary",
                                      },
                                    },
                                    [_vm._v("大考中心")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              item.announcer == "cac"
                                ? _c(
                                    "v-chip",
                                    {
                                      attrs: {
                                        small: "",
                                        label: "",
                                        "text-color": "white",
                                        color: "cyan",
                                      },
                                    },
                                    [_vm._v("甄委會")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              item.announcer == "uac"
                                ? _c(
                                    "v-chip",
                                    {
                                      attrs: {
                                        small: "",
                                        label: "",
                                        "text-color": "white",
                                        color: "amber",
                                      },
                                    },
                                    [_vm._v("考分會")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              item.announcer == "jbcrc"
                                ? _c(
                                    "v-chip",
                                    {
                                      attrs: {
                                        small: "",
                                        label: "",
                                        "text-color": "white",
                                        color: "green",
                                      },
                                    },
                                    [_vm._v("招聯會")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              item.announcer == "cape"
                                ? _c(
                                    "v-chip",
                                    {
                                      attrs: {
                                        small: "",
                                        label: "",
                                        "text-color": "white",
                                        color: "indigo",
                                      },
                                    },
                                    [_vm._v("術聯會")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "v-list-tile-title",
                                _vm._l(item.titles, function (t) {
                                  return _c(
                                    "v-btn",
                                    {
                                      key: t.title,
                                      attrs: {
                                        flat: "",
                                        small: "",
                                        href: t.link,
                                        target: "_blank",
                                      },
                                    },
                                    [_vm._v(_vm._s(t.title))]
                                  )
                                }),
                                1
                              ),
                              _vm._v(" "),
                              _vm.$route.path != "/admin/published"
                                ? _c(
                                    "v-list-tile-action",
                                    {
                                      on: {
                                        click: function ($event) {
                                          _vm.$emit(
                                            "setState",
                                            [item],
                                            "published"
                                          )
                                          _vm.remove(index)
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { bottom: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function (ref) {
                                                  var on = ref.on
                                                  return [
                                                    _c(
                                                      "v-btn",
                                                      _vm._g(
                                                        {
                                                          attrs: {
                                                            icon: "",
                                                            ripple: "",
                                                          },
                                                        },
                                                        on
                                                      ),
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            attrs: {
                                                              color:
                                                                "grey darken-1",
                                                            },
                                                          },
                                                          [_vm._v("done")]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        },
                                        [
                                          _vm._v(" "),
                                          _c("span", [_vm._v("發佈")]),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.$route.path != "/admin/deleted"
                                ? _c(
                                    "v-list-tile-action",
                                    {
                                      on: {
                                        click: function ($event) {
                                          _vm.$emit(
                                            "setState",
                                            [item],
                                            "deleted"
                                          )
                                          _vm.remove(index)
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { bottom: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function (ref) {
                                                  var on = ref.on
                                                  return [
                                                    _c(
                                                      "v-btn",
                                                      _vm._g(
                                                        {
                                                          attrs: {
                                                            icon: "",
                                                            ripple: "",
                                                          },
                                                        },
                                                        on
                                                      ),
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            attrs: {
                                                              color:
                                                                "grey darken-1",
                                                            },
                                                          },
                                                          [_vm._v("delete")]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        },
                                        [
                                          _vm._v(" "),
                                          _c("span", [_vm._v("刪除")]),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "text-xs-center" },
                    [
                      _c("v-pagination", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.news[0],
                            expression: "news[0]",
                          },
                        ],
                        attrs: { length: _vm.news.length },
                        model: {
                          value: _vm.page,
                          callback: function ($$v) {
                            _vm.page = $$v
                          },
                          expression: "page",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }