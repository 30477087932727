import { render, staticRenderFns } from "./news_manager.vue?vue&type=template&id=69f0db5f&"
import script from "./news_manager.vue?vue&type=script&lang=js&"
export * from "./news_manager.vue?vue&type=script&lang=js&"
import style0 from "./news_manager.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/nsdua/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('69f0db5f')) {
      api.createRecord('69f0db5f', component.options)
    } else {
      api.reload('69f0db5f', component.options)
    }
    module.hot.accept("./news_manager.vue?vue&type=template&id=69f0db5f&", function () {
      api.rerender('69f0db5f', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "frontend/page/_admin/news_manager.vue"
export default component.exports