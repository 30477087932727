// navbar data

export default {
    props: {
        Open: {
            type: Boolean,
            default: false,
        },
        Below: {
            type: Boolean,
            default: false,
        },
        Items: {
            type: Array,
            default: () => [],
        },
        Title: {
            type: String,
            default: '多元入學升學網',
        },
    },
    data () { return {
        title: this.Title,
        drawer: {
            open: this.Open,        // sets the open status of the drawer
            clipped: true,          // sets if the drawer is shown above (false) or below (true) the toolbar
            temporary: !this.Below, // sets if the drawer sits above its application and uses a scrim (overlay) to darken the background
            fixed: false,           // sets if the drawer is CSS positioned as 'fixed'
            permanent: false,       // sets if the drawer remains visible all the time (true) or not (false)
            mini: false,            // sets the drawer to the mini variant, showing only icons, of itself (true) or showing the full drawer (false)
            floating: true,
            items: this.Items,
        },
        toolbar: {
            fixed: true,        
            clippedLeft: true,      // sets if the toolbar contents is leaving space for drawer (false) or not (true)
        },
    }},
}
