<template>
<div class="page_home_other col-lg-8 mb-8">

    <!-- title -->
    <v-chip class="font-size:3rem;" text-color="white" color="primary">{{content[$route.path].name}}</v-chip>
    <br><br>

    <!-- images -->
    <div v-for="pic in content[$route.path].pics" :key="pic.name">
        <img :src="pic" width="100%">
    </div>

    <!-- text -->
    <div v-html="content[$route.path].text"></div>
    
</div>
</template>

<script>
export default {
    watch: {
        async $route (to, from){
            window.scrollTo(0, 0)
        },
    },
    data() {
        return {
            content: {
                '/faq/faq-1': {
                    name: '常見問題 / 考試',
                    pics: [
                        require('page/_home/img/faq-1-1.png'),
                        require('page/_home/img/faq-1-2.png'),
                        require('page/_home/img/faq-1-3.png'),
                        require('page/_home/img/faq-1-4.png'),
                    ],
                },
                '/faq/faq-2': {
                    name: '常見問題 / 招生',
                    pics: [
                        require('page/_home/img/faq-2-1.png'),
                        require('page/_home/img/faq-2-2.png'),
                        require('page/_home/img/faq-2-3.png'),
                        require('page/_home/img/faq-2-4.png'),
                        require('page/_home/img/faq-2-5.png'),
                    ],
                },
                '/faq/faq-3': {
                    name: '常見問題 / 諮詢',
                    pics: [require('page/_home/img/faq-3-1.png')],
                },
                '/service': {
                    name: '諮詢管道',
                    pics: [
                        require('page/_home/img/U2/U2_01.gif'),
                        require('page/_home/img/U2/U2_02.gif'),
                        require('page/_home/img/U2/U2_03.gif'),
                        require('page/_home/img/U2/U2_04.gif'),
                        require('page/_home/img/U2/U2_05.gif'),
                        require('page/_home/img/U2/U2_06.gif'),
                        require('page/_home/img/U2/U2_07.gif')
                    ],
                },
                '/about': {
                    name: '理念與精神',
                    pics: [require('page/_home/img/about.png')],
                },
                '/examinations/exam-1': {
                    name: '考試方式 / 學科能力測驗',
                    pics: [require('page/_home/img/exam-1.png')],
                },
                '/examinations/exam-2': {
                    name: '考試方式 / 指定科目考試',
                    pics: [require('page/_home/img/exam-2.png')],
                },
                '/examinations/exam-3': {
                    name: '考試方式 / 術科考試',
                    pics: [require('page/_home/img/exam-3.png')],
                },
                '/examinations/exam-4': {
                    name: '考試方式 / 高中英文聽力測驗',
                    pics: [require('page/_home/img/exam-4.png')],
                },
                '/admissions/admission-1': {
                    name: '入學管道 / 繁星推薦',
                    pics: [
                        require('page/_home/img/admission1-3.png'),
                        require('page/_home/img/admission1-1.png'),
                        require('page/_home/img/admission1-2.png')
                    ],
                },
                '/admissions/admission-2': {
                    name: '入學管道 / 申請入學',
                    pics: [
                        require('page/_home/img/admission2-2.png'),
                        require('page/_home/img/admission2-1.png'),
                    ],
                },
                '/admissions/admission-3': {
                    name: '入學管道 / 分發入學',
                    pics: [
                        require('page/_home/img/admission3-2.png'),
                        require('page/_home/img/admission3-1.png'),
                    ],
                },
                '/admissions/admission-4': {
                    name: '入學管道 / 特殊選才',
                    pics: [
                        require('page/_home/img/admission4-2.png'),
                        require('page/_home/img/admission4-1.png'),
                    ],
                },
                '/admissions/admission-5': {
                    name: '入學管道 / 單獨招生',
                    pics: [require('page/_home/img/admission5.png')],
                },
                '/relations': {
                    name: '考試與入學管道',
                    pics: [require('page/_home/img/relation.png')],
                },
                '/schedule': {
                    name: '重要日程表',
                    pics: [
                        require('page/_home/img/im-1.png'),
                        require('page/_home/img/im-2.png'),
                    ],
                },
                '/revision/revision-1': {
                    name: '114 考招調整 / 招生調整',
                    pics: [require('page/_home/img/revision1.png')],
                },
                '/revision/revision-2': {
                    name: '114 考招調整 / 考試調整',
                    pics: [require('page/_home/img/revision2.png')],
                },
                '/srecruit-1': {
                    name: '特殊選才招生管道',
                    pics: [
                        require('page/_home/img/admission4-2.png'),
                        require('page/_home/img/admission4-1.png'),
                    ],
                },
                '/collego': {
                    name: 'ColleGo!系統簡介',
                    pics: [require('page/_home/img/collego.png')],
                },
                '/note': {
                    name: '重要叮嚀事項',
                    text: `
                        1.	經「114學年度大學辦理特殊選才招生計畫」( 以下簡稱特殊選才) 錄取且完成報到之考生，未於錄取學校規定之期限內放棄錄取資格者，一律不得參加114學年度大學繁星推薦招生( 以下簡稱繁星推薦)、大學申請入學招生( 以下簡稱申請入學)、大學分發入學招生( 以下簡稱分發入學)、科技校院繁星計畫聯合推薦甄選入學、科技校院四年制及專科學校二年制特殊選才入學聯合招生、科技校院日間部四年制申請入學聯合招生、四技二專之技優保送入學、技優甄審入學、甄選入學與日間部聯合登記分發入學。<br><br>
                        2.	報名參加大學「繁星推薦」及「申請入學」招生之每一考生，皆應至<a href="https://www.cac.edu.tw/" target="_blank">甄選會網址</a>，自行設定個人專屬之密碼。本組密碼係為報名、錄取結果(或篩選、分發結果)查詢、上傳審查資料、登記志願序、網路聲明放棄入學資格作業等系統所需輸入的證號，請妥善保管，切勿將密碼吿知他人。<br><br>
                        3.	考生已報名「繁星推薦」者不得重複報名「科技校院繁星計畫」；「繁星推薦」第1∼7類學群錄取生，無論放棄與否，一律不得報名「申請入學」，亦不得參加「科技校院日間部四年制申請入學」第一階段篩選。「繁星推薦」第8類學群通過篩選學生不得報名「申請入學」同一大學之同一校系，「繁星推薦」第8類學群錄取生不得參加「申請入學」統一分發。<br><br>
                        4.	考生通過「申請入學」第一階段篩選後，務必記得參閱招生簡章各大學第二階段指定項目甄試之規定，或上網參閱校系公告之相關訊息。第二階段指定項目甄試，各大學校系規定繳交之審查資料，除另有規定外，一律以網路上傳(勾選)方式繳交至甄選會。校系如有要求課程學習成果、多元表現之審查項目，考生可自高級中等教育階段學生學習歷程資料庫(以下簡稱學習歷程中央資料庫)中勾選資料檔案後上傳，或自行製作PDF格式檔案後上傳，每一校系「勾選上傳」或「PDF上傳」僅限擇一方式辦理；考生若於學習歷程中央資料庫無資料檔案者，皆以「PDF上傳」方式辦理。<br><br>
                        5.	「申請入學」正備取生、「114學年度離島地區及原住民籍高級中等學校應屆畢業生升學國(市) 立師範及教育大學聯合保送甄試」(以下簡稱師資保送) 正備取生及「114學年度原住民族及離島地區醫事人員養成計畫公費生甄試」(以下簡稱醫事保送)正備取生，應同時於114/6/5~6/6至「甄選會」網站登記就讀志願序，未上網登記視同放棄錄取資格。考生需在期間內完成上網選填志願序，且務必將志願表存檔或列印，作為完成登記之憑據。「繁星推薦」第8類學群錄取者，無論放棄與否，一律不得再參加「申請入學」網路就讀志願序登記。<br><br>
                        6.	「申請入學」未獲分發錄取欲參加「分發入學」的考生，務必把握時間於114/6/17前向大考中心報名分科測驗。<br><br>
                        7.	參加分發入學招生之考生除下列兩者外，均須於114/6/20 前上傳學力證明文件至考分會，經審查通過後，始可參與分發：（1）由學校（不含非學校型態實驗教育機構）集體報名分科測驗之應屆畢業生。（2）曾於93-113學年度參與分發入學並通過「學力證明文件審查」之考生。<br><br>
                        8.	原住民等特種生欲申請加分優待者，均須於114/6/20前向考分會提出特種生審查申請，經審查通過後，始可享有加分優待。<br><br>
                        9.	所有考生（含術科音樂組考生）均須報名至少1科分科測驗，始得參與分發入學。考生可參考分發入學校系分則，依校系規定選擇報考科目。<br><br>
                        10.	分發入學採網路登記志願，考生可向考分會購買「114 學年度大學分發入學登記相關資訊」參考，於繳交登記費（114/7/29 上午9:00~8/4 中午12:00）後，才能至考分會網站「登記分發志願系統」設定通行碼並選填志願。考生務必妥善保管通行碼及相關個人資料，以防被冒用。<br><br>
                        11.	分發入學選填志願時間為四天（114/8/1 上午9:00~8/4 下午4:30），一定要在期間內至考分會網站選填並送出志願，並將考分會回傳之志願表存檔或列印，作為完成登記之憑據。<br><br>
                        ※溫馨提醒：報名各項考試或招生前，應詳閱各考試或招生簡章之規定。<br><br>
                    `,
                },
                '/plan': {
                    name: '入學方案簡表',
                    pics: [
                        require('page/_home/img/plan-1.png'),
                        require('page/_home/img/plan-2.png'),
                        require('page/_home/img/plan-3.png'),
                    ],
                },
                '/flowchart': {
                    name: '入學方案架構圖',
                    pics: [require('page/_home/img/flowchart.png')],
                },
                '/agency': {
                    name: '考試及招生單位',
                    pics: [
                        require('page/_home/img/U1/U1_01.gif'),
                        require('page/_home/img/U1/U1_02.gif'),
                        require('page/_home/img/U1/U1_03.gif'),
                        require('page/_home/img/U1/U1_04.gif'),
                        require('page/_home/img/U1/U1_05.gif'),
                        require('page/_home/img/U1/U1_06.gif'),
                    ],
                },
                '/calendar': {
                    name: '114 學年度行事曆',
                    text: `
                        <iframe src="https://calendar.google.com/calendar/embed?height=600&amp;wkst=2&amp;bgcolor=%23ffffff&amp;ctz=Asia%2FTaipei&amp;src=bDMybjRidWoyZDl1YXVwamVmZTY5dnA2ZGdAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;src=NGpvMnRwMGEyZjJzNWdmbHM4dTNxaTVuMTRAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;src=NzVmYWhxNW43OXE4OXM5M3F1Y2Y5MzlnbW9AZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;src=ODI4dXZrcjI2MTUwcG5icWgwZTM2Nm9kbWdAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;src=cWN2cXAzNGJ1cGw1ZGxsOW52ZzZobXEyMmtAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;src=YnNlMGUwNW9qMmd0aWp1cWVyaDBmMXA2OW9AZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;src=aDM0ZWxxZ2VpY2d1Z2d0N2pydW1mcm1jNHNAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;color=%239E69AF&amp;color=%23AD1457&amp;color=%23EF6C00&amp;color=%23009688&amp;color=%237986CB&amp;color=%23C0CA33&amp;color=%23E67C73&amp;showTitle=0" style="border:solid 1px #777" width="100%" height="600" frameborder="0" scrolling="no"></iframe>
                    `
                },
            }
        }
    },
}
</script>

<style lang="scss">
.page_home_other {
    .v-chip__content {
        font-size: 1.5rem;
    }
}
</style>
