var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page_home_download col-lg-8 mb-8" },
    [
      _c(
        "v-chip",
        {
          staticClass: "font-size:3rem;",
          attrs: { "text-color": "white", color: "primary" },
        },
        [_vm._v("資料下載")]
      ),
      _vm._v(" "),
      _c("br"),
      _c("br"),
      _vm._v(" "),
      _c(
        "v-list",
        [
          _c("v-subheader", [_vm._v("宣導手冊")]),
          _vm._v(" "),
          _vm._l(_vm.files1, function (file) {
            return _c(
              "v-list-tile",
              {
                key: file.name,
                attrs: {
                  href: "/download/" + file.name + ".pdf",
                  target: "_blank",
                  ripple: "",
                },
              },
              [
                _c(
                  "v-list-tile-content",
                  [_c("v-list-tile-title", [_vm._v(_vm._s(file.name))])],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-list-tile-action",
                  [
                    _c("v-subheader", { attrs: { inset: "" } }, [
                      _vm._v(_vm._s(file.size)),
                    ]),
                  ],
                  1
                ),
              ],
              1
            )
          }),
          _vm._v(" "),
          _c("v-divider"),
          _vm._v(" "),
          _c("v-subheader", [_vm._v("方案介紹")]),
          _vm._v(" "),
          _vm._l(_vm.files2, function (file) {
            return _c(
              "v-list-tile",
              {
                key: file.name,
                attrs: {
                  href: "/download/" + file.name + ".pdf",
                  target: "_blank",
                  ripple: "",
                },
              },
              [
                _c(
                  "v-list-tile-content",
                  [_c("v-list-tile-title", [_vm._v(_vm._s(file.name))])],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-list-tile-action",
                  [
                    _c("v-subheader", { attrs: { inset: "" } }, [
                      _vm._v(_vm._s(file.size)),
                    ]),
                  ],
                  1
                ),
              ],
              1
            )
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }