<template>
	<div class="page_home_download col-lg-8 mb-8">
		<!-- title -->
		<v-chip class="font-size:3rem;" text-color="white" color="primary">常見問題</v-chip>
		<br><br>
		<div class="card mb-4">
			<div class="card-body reminder">
				<h2 class="card-title">考試</h2>
				<p class="card-text"></p>

				<!--exam-->

				<p></p>
				<h5>1.我一定要參加學測嗎？</h5>
				<div class="qa-text">
					<img height="25" :src="content.qa" width="25" />視選擇的入學管道及校系而定，繁星推薦及申請入學除術科校系可能不參採外，餘均參採學測成績。 111 學年度起分發入學管道，大學校系除可以學測成績作為檢定外，亦可採計學測成績，考生應於學測報名前，確認有意報考的校系是否使用學測成績。
				</div>

				<p></p>
				<h5>2.我要如何選擇學測考試的考科？</h5>
				<div class="qa-text">
					<img height="25" :src="content.qa" width="25" />視您選擇的校系而定。繁星推薦使用學測成績作為檢定、分發比序項目，申請入學使用學測成績作為第一階段的檢定、倍率篩選及同級分超額篩選比序、第二階段的成績採計，分發入學則可將學測成績作為檢定或採計。請先參考各招生簡章之校系分則，查明您有意報名、就讀的校系使用哪些學測科目作為檢定、倍率篩選、採計及分發比序，就會知道您需要報考哪些學測考科。各入學管道會在當學年度學測報名前公告校系參採之學測科目：
					<br>(1)	繁星推薦：請至<a href="https://www.cac.edu.tw" target="_blank">甄選會網站</a>選擇「繁星推薦」進入「訊息公告」或「校系分則查詢」
					<br>(2)	申請入學：請至<a href="https://www.cac.edu.tw" target="_blank">甄選會</a>選擇「申請入學」進入「訊息公告」或「校系分則查詢」
					<br>(3)	分發入學：請至<a href="https://www.uac.edu.tw" target="_blank">考分會網站</a>選擇「最新消息」或「校系分則」。
				</div>

				<p></p>
				<h5>3.如果學測有一科為零級分，可以參加繁星推薦及申請入學嗎？</h5>
				<div class="qa-text">
					<img height="25" :src="content.qa" width="25" />自 107 學年度起，取消考生若有學測任一科為零級分不得參加分發比序/第一階段篩選之規定。只要考生有意報名的校系所使用到的學測科目級分加總後不為零級分就可以參加該管道招生，但是若校系訂為檢定之學測科目為零分的話，將無法通過檢定，也就無法參加繁星推薦分發比序或申請入學第一階段之倍率篩選。
				</div>

				<p></p>
				<h5>4.學測的成績可以保留到明年使用嗎？</h5>
				<div class="qa-text">
					<img height="25" :src="content.qa" width="25" />不可以。
					<br>學科能力測驗成績只能在當學年度的入學管道中使用，並不能跨年度使用。「學科能力測驗成績保留五年」的意思是指大考中心為考生的學測成績保留五年，五年之中如果考生有需要，可向大考中心申請成績證明。
					<br>教育部為鼓勵學生先累積社會歷練，多元探索志趣，推動「青年教育與就業儲蓄帳戶方案」，並配合提供更為暢通與彈性的就學管道，著重在學生參與職場、學習及國際體驗的資歷，使青年於計畫執行完成後，可繼續接軌升學。爰如您參加「青年教育與就業儲蓄帳戶方案」且計畫執行完成後，擬繼續接軌升學得於規定期限內，持「原畢業年度學科能力測驗成績」，參加申請入學之青年儲蓄帳戶組招生。(有關「青年教育與就業儲蓄帳戶方案」相關事宜，得至<a href="https://www.edu.tw/1013/" target="_blank">本部青年教育與就業儲蓄帳戶專區</a>網頁查詢或逕洽青年教育與就業儲蓄帳戶專案辦公室(電話: (02)7736-5422))。
				</div>

				<p></p>
				<h5>5.如果沒有參加學測，只參加分科測驗，是否可以參加「分發入學」招生管道？</h5>
				<div class="qa-text">
					<img height="25" :src="content.qa" width="25" />可以，但只能選擇「分發入學」管道無需參採學測之校系。相關資訊可至<a href="https://www.uac.edu.tw" target="_blank">考分會網站</a>選擇「校系分則」查詢。
				</div>

				<p></p>
				<h5>6.我一定要參加英聽或術科考試嗎？ </h5>
				<div class="qa-text">
					<img height="25" :src="content.qa" width="25" />視您選擇的入學管道而定。各入學管道會在當學年度英聽或術科考試報名前，公告檢定英聽的校系及參加術科考試的校系，考生可查閱當學年度繁星推薦、申請入學及分發入學3本招生簡章或直接上各入學管道網頁查詢，視自己欲報考的校系是否採計英聽或術科，來決定要不要參加英聽測驗或術科考試。
				</div>

				<p></p>
				<h5>7.我要如何選擇分科測驗的考科？ </h5>
				<div class="qa-text">
					<img height="25" :src="content.qa" width="25" />如果您要報名的A學系採計分科測驗的物理、化學，您就一定要選考物理、化學。假設只選考化學，就會因「任一採計科目未報考」而無法分發A學系。
				</div>

				<p></p>
				<h5>8.學測、分科考試或術科單項若有1科為零分，可以參加分發入學登記分發嗎？</h5>
				<div class="qa-text">
					<img height="25" :src="content.qa" width="25" />可以，但仍需要視您選擇的校系而定。
					<br>若您擇定的校系所檢定的學測科目為零分，將無法通過檢定，無法參加分發。若分科測驗有1科目為零分的話，可以參加分發，但考科組合未加權之級分和須達到最低登記標準，才可以選填採計該組合的校系。另外，部分採計術科之校系，設有術科各單項0分不予分發的限制。詳見 114學年度大學分發入學招生簡章「貳、校系分則」中各校系之「選系說明」。
				</div>

				<p></p>
				<h5>9.我要如何準備大學入學相關考試呢？</h5>
				<div class="qa-text">
					<img height="25" :src="content.qa" width="25" />歡迎各位考生至<a href="https://www.ceec.edu.tw/" target="_blank">大考中心網站</a>查詢考試資訊、各項應試規則及注意事項：
					<br>(一)	考試資訊：
					<br>(1)	大考中心為便利民眾瀏覽，於官網設有高中英語聽力測驗、學科能力測驗、分科測驗(110前指考)等考試專區，且於各考試專區下設有「考試訊息、試務專區、簡介、考試說明」等訊息，其中「考試訊息」刊登該次考試相關的即時資訊。
					<br>(2)	此外，各考試專區還有提供試題相關內容，如：考試說明，內含試題擧例；參考試卷，提供試卷可能架構；歷年試題，包括歷年正式考試試題與答案。適用108課綱之試題，可參考111至113學年度之學測及分科測驗各科試題、答題卷、參考答案與非選擇題評分原則。英聽測驗則可參考每年度公布的一卷試題示例，其中111至113年所公布者為依據108課綱所命試題。各項資訊，歡迎考生瀏覽或下載。
					<br>(3)	另大考中心網站「宣導與研習」專區，置放了各科命題方向等相關影音資料、簡報，包括部分試題分析的影片，考生可線上瀏覽或下載。
					<br>(二)	各項應試規則與應考注意事項：除簡章與各項考試專區之考試訊息外，可至「考生試務宣導」專區，透過影音媒介與各類說明，提醒考生各項應考注意事項。
				</div>

				<p></p>
				<h5>10.答題卷上印有本人應試號碼與姓名，依簡章規定考生仍須簽名？</h5>
				<div class="qa-text">
					<img height="25" :src="content.qa" width="25" />111學年度起使用卷卡合一答題卷，並新增簽名欄位，若考生未於「確認後考生簽名」欄以正楷簽全名，會依違規處理辦法扣減成績。
					<br>英聽：於考試開始鈴聲響畢後，應依播音指示簽名。
					<br>學測與分科測驗：各節次考試開始鈴響起，考生即可於答題卷上之「確認後考生簽名」欄，以正楷簽全名。
					<br>另各項考試，如因特殊情況使用備用答題卷，卷上雖無考生本人應試號碼與姓名，仍須於「確認後考生簽名」欄以正楷簽全名。
				</div>

				<p></p>
				<h5>11.114分科測驗數學乙要考哪些內容？有哪些資訊可以參考？要如何準備？ </h5>
				<div class="qa-text">
					<img height="25" :src="content.qa" width="25" />
					<br>(1)	114學年度分科測驗數學乙考科的測驗範圍包含10年級必修數學、11年級必修數學A類及數學B類均關聯的學習內容，以及12年級加深加廣選修數學乙類。如欲詳加了解該考科之測驗目標、測驗內容與試題舉例，可逕至大考中心網站-分科測驗(110前指考)-考試說明專區，參閱「分科測驗數學考科考試說明-114學年度起適用」(112年5月2日更新)。
					<br>(2)	又為進一步提供各界了解數學乙考科整份試卷之樣貌，大考中心亦於分科測驗(110前指考)-參考試卷專區，公布「114學年度起適用之分科測驗數學乙考科參考試卷」。此次公布之參考試卷計兩卷，每卷皆包含整卷試題、答題卷、參考答案、評分原則，以及試題解析等，提供大家了解組卷或有不同之題卷樣貌與題型配置，其中答題卷部分提供兩種版面形式：A3正式考試版與A4校園列印版，A3正式考試版可列印後做為模擬練習使用。
					<br>(3)	分科測驗數學乙考科題型包含單選、多選、選塡與混合題型，其中有關混合題型形式，另可以參閱大考中心網站學科能力測驗-歷屆試題及答題卷(111至113學年度)學測數學A、數學B試卷，或是分科測驗-歷屆試題及答題卷(111至113學年度)數學甲試卷，將有助考生了解混合題型的命題方式與答題卷設計。
				</div>

				<p></p>
				<h5>12.請問我不是108課綱的學生(如重考生、境外返國、高中肄業等)，可以報名111學年度以後的英聽、學測、術科、分科測驗等考試嗎？</h5>
				<div class="qa-text">
					<img height="25" :src="content.qa" width="25" />可以，考生符合報考資格均得報考，與是否為 108 課綱學生無涉。
				</div>
				
				<!--enrollment-->

				<p></p>
				<h5>13.我已錄取特殊選才且完成報到，可以參加大學多元入學或四技二專各招生管道嗎？</h5>
				<div class="qa-text">
					<img height="25" :src="content.qa" width="25" />不可以。
					<br>經特殊選才錄取且完成報到之考生，未於錄取學校規定之期限內放棄錄取資格者，不得參加當學年度大學繁星推薦、大學申請入學、大學分發入學、科技校院繁星計畫聯合推薦甄選入學、科技校院四年制及專科學校二年制特殊選才入學聯合招生、科技校院日間部四年制申請入學聯合招生、四技二專之技優保送入學、技優甄審入學、甄選入學、日間部聯合登記分發入學。另溫馨提醒經特殊選才錄取且完成報到之考生，如符合就讀高中繁星推薦資格，於就讀高中辦理高中校內進行推薦作業時，應主動吿知就讀高中，以利高中校內推薦作業進行。
				</div>

				<p></p>
				<h5>14.請問繁星推薦成績之總平均計算方式及高中推薦程序？</h5>
				<div class="qa-text">
					<img height="25" :src="content.qa" width="25" />繁星推薦自100學年度納入多元入學方案迄今，涉學業成績總平均計算方式、高中推薦程序均未改變，說明如下：
					<br>(1)	繁星推薦所採計「各學期學業成績總平均」之計算：各高中應依據高級中等學校學生學習評量辦法之規定辦理。但如有重修或補考之科目，應以原成績辦理；重讀之科目，應以再次選讀所得之成績辦理。
					<br>※依「高級中等學校學生學習評量辦法」第七條第一項規定：學期學業成績總平均之計算，為各科目學期學業成績乘以各該科目學分數所得之總和，再除以總學分數。
					<br>(2)	各高中辦理「繁星推薦」推薦作業：依大學招生委員會聯合會大學繁星推薦招生規定，應成立推薦委員會，並訂定推薦原則及程序等有關規定，在校內公布後審慎辦理。招生簡章並未統一訂定各高中學校辦理推薦之方式，係考量各高中學校之推薦序會因各校科別不同、學生數及課程情形等迥異，仍宜由各高中自訂公平合宜之推薦機制，提校內推薦委員會同意後公布辦理。因此，高中應明訂公平、公正、公開之推薦原則(含成績處理、推薦資格、推薦順序等審定原則)及程序，並於學生入學時詳細說明及公布。
				</div>

				<p></p>
				<h5>15.我同時錄取大學申請入學招生及科技校院日間部四年制申請入學聯合招生，想走科技校院四年制申請入學，卻發現沒有在期限內放棄大學招生管道的錄取資格，是否還有其他方法挽救？</h5>
				<div class="qa-text">
					<img height="25" :src="content.qa" width="25" />無法補救。
					<br>若超過招生簡章規定的放棄錄取時間，則無法再補救。提醒同學，無論有意參與技專院校之升學管道，或大學繁星推薦、申請入學招生管道，皆必須注意其招生簡章所定之重要日程。
				</div>

				<p></p>
				<h5>16.若想放棄繁星推薦錄取的校系，改走分發入學管道，卻錯過繁星推薦的放棄時間點，還可以補放棄嗎？</h5>
				<div class="qa-text">
					<img height="25" :src="content.qa" width="25" />不可以。
					<br>承接上題，同學必須特別留意放棄時間，一旦錯過就無法再補放棄。
				</div>

				<p></p>
				<h5>17.我通過繁星推薦第8類學群第1階段篩選，可以參加申請入學管道嗎？</h5>
				<div class="qa-text">
					<img height="25" :src="content.qa" width="25" />可以，但不得再於申請入學管道重複報名已通過篩選(繁星推薦第 8 類學群)之同一大學之同一校系。
				</div>

				<p></p>
				<h5>18.我錄取繁星推薦第8類學群，可以參加申請入學登記就讀志願序嗎？</h5>
				<div class="qa-text">
					<img height="25" :src="content.qa" width="25" />不可以，第8類學群錄取生，不得上網登記就讀志願序，亦不得參加申請入學統一分發。
				</div>

				<p></p>
				<h5>19.大學端是怎麼審查我的審查資料？準備方向是什麼呢？ </h5>
				<div class="qa-text">
					<img height="25" :src="content.qa" width="25" />為了讓學生製作審查資料時，有更明確的依循方向，以113學年度為例：
					<br>(1)	各大學校系於113年3月1日前公告申請入學審查資料準備指引，提供學生第二階段審查資料準備參考。且為了方便考生及家長查閱，自113學年度起，甄選會已彙整各大學準備指引連結頁面，考生及家長得在113年3月1日起至<a href="https://www.cac.edu.tw/" target="_blank">甄選會網址</a>，選擇「申請入學」，進入「審查資料準備指引」項下查詢。
					<br>(2)	招聯會已製作<a href="https://www.youtube.com/@user-vt9ed3nn1j" target="_blank">三分鐘小講堂系列影片</a>，希幫助學生能更了解大學端怎麼審查資料、看的重點是什麼；也可以到<a href="http://www.jbcrc.edu.tw/" target="_blank">招聯會網站</a>查詢或直接電話洽詢(02)2368-1913。
				</div>

				<p></p>
				<h5>20.大學申請入學第2階段指定項目甄試審查資料需要印製精美、面試時需穿著西裝或套裝？ </h5>
				<div class="qa-text">
					<img height="25" :src="content.qa" width="25" />不需要。
					<br>招聯會澄清，自103學年度起已經全面實施線上書審，完全不用花錢印製。申請入學第二階段中指定項目審查資料早已不是「集點制」，採「全方位審查」，許多大學審查委員更表示，是否有「第三方製作」，大學教授有能力分辨，看的重點是內容與配合的素材跟申請系所之間的關聯，利用面試機會驗證書審資料的真實性；尤其提醒審查委員要挑的是人才，與穿著打扮、儀容沒關係，一般性的穿著即可。
				</div>

				<p></p>
				<h5>21.應屆畢業生報考大學申請入學時，要如何上傳學習歷程檔案給大學呢？</h5>
				<div class="qa-text">
					<img height="25" :src="content.qa" width="25" />依照教育部國民及學前教育署規定，每學年提交學習歷程檔案至學習歷程中央資料庫，於報考大學申請入學通過第一階段的考生，針對校系要求之課程學習成果、多元表現等審查資料，即可於資料庫中勾選後上傳給大學。
				</div>

				<p></p>
				<h5>22.大學申請入學通過一階篩選後，可以提前熟悉網路上傳(勾選)審查資料之操作介面及作業流程嗎？</h5>
				<div class="qa-text">
					<img height="25" :src="content.qa" width="25" />可以。
					<br>甄選會為利考生預先瞭解並熟悉申請入學第二階段網路上傳(勾選)審查資料之操作介面及作業流程，訂於114年4月中旬開放測試系統供考生實作演練及提前檢視歷程資料。測試系統網址為：https://www.cac.edu.tw/，進入後選擇「申請入學」網頁，再點選「審查資料上傳」項下之「第二階段審查資料上傳系統(測試版)」，即可進行審查資料上傳測試作業。
					<br>考生若為當學年度學術群之應屆畢業生，測試版期間登入後可檢視學習歷程中央資料庫第1至第4學期之學習歷程檔案資料；正式版期間登入後可檢視學習歷程中央資料庫第1至第6學期之學習歷程檔案資料。
					<br>針對甄選會系統內，其考生檢視的修課紀錄、課程學習成果、多元表現等均由學習歷程中央資料庫提供，如對於檔案資料有疑義，務必依簡章規定期限內立即向就讀高中進行反映，以維護自身權益。
					<br>若考生於操作上傳(勾選)時有疑問，可至「申請入學」網站，點選「審查資料上傳」頁面「審查資料上傳相關事項」之「審查資料上傳常見問題Q&A」查看，或是致電甄選會(05-2721799)洽詢。
				</div>

				<p></p>
				<h5>23.進行審查資料上傳前，我所使用的設備需要有哪些作業系統或軟體？ </h5>
				<div class="qa-text">
					<img height="25" :src="content.qa" width="25" />建議使用桌上型電腦或筆記型電腦進行審查資料上傳，且電腦建議安裝Adobe官方網站Adobe Acrobat Reader之PDF 閱讀器最新版本，此外，建議使用Microsoft office2007以上版本之文書處理軟體產生PDF。
				</div>

				<p></p>
				<h5>24.請問申請入學的審查資料上傳，經確認後還可以補件或抽換嗎？</h5>
				<div class="qa-text">
					<img height="25" :src="content.qa" width="25" />審查資料於上傳系統確認送出後，如有補件、抽換等需求，請依各大學規定辦理，並請逕洽各報考大學。
					<br>(1)	以113學年度為例，依113學年度大學申請入學招生簡章規定：「上傳之審查資料於確認前皆可重複上傳(或重新勾選)，亦即考生若欲修改資料內容時，可將修改後之檔案重新上傳(或自學習歷程中央資料庫中重新勾選上傳)。惟若審查資料一經確認後，一律不得以任何理由要求修改，請考生務必審愼檢視上傳之每一大項資料是否完整後再行確認。」爰審查資料一經確認後，一律不得以任何理由要求修改。
					<br>(2)	依113學年度大學申請入學招生簡章規定：「甄選委員會於各校系規定繳交截止日後，逕將考生已完成上傳確認之審查資料及曾上傳(勾選)但未確認之審查資料轉送各大學。考生得否補件、抽換審查資料或參加第二階段指定項目甄試，依各大學規定辦理，考生不得異議。」爰考生得否補件、抽換審查資料或參加第二階段指定項目甄試，依各大學規定辦理。
				</div>

				<p></p>
				<h5>25.我是技術型高中專業群科學生，想要繳交包含高三之學習歷程檔案，要如何繳交呢？</h5>
				<div class="qa-text">
					<img height="25" :src="content.qa" width="25" />依教育部國民及學前教育署所定學習歷程檔案提交至學習歷程中央資料庫時程規劃，「專業群」班別(範疇包括各專業群科、綜合高中專門學程、實用技能學程)之應屆畢業生，其高三「課程學習成果」、「多元表現」之學習歷程檔案於114年5月底才完成提交，故此類考生審查資料繳交方式，若選擇「勾選上傳」者，僅提供檢視及勾選第一至第四學期之學習歷程檔案；若欲繳交包含高三之學習歷程檔案者，則須選擇繳交方式為「PDF上傳」，由考生自行將第一至第六學期之學習歷程檔案製作成PDF檔案上傳。
				</div>

				<p></p>
				<h5>26.大學審查審查資料行之已久，各大學校系有沒有審查時共通的原則呢？</h5>
				<div class="qa-text">
					<img height="25" :src="content.qa" width="25" />招聯會已彙整出的「大學針對申請入學備審資料審查參考原則」有「三重二不」原則，分別是「重視基本素養所展現的核心能力」、「重視校內的學習活動」、「重視資料真實性及學生自主準備」、「不是學系所列的所有項次都要具備，大學重視多面向的參採」、以及「不是以量取勝，重視學習過程的反思」，不會因為高中開課差異、都市偏鄉資源的不同，就影響到審查的公平性，反而透過學習歷程檔案更能參酌學生的實際情況。
				</div>

				<p></p>
				<h5>27.課程學習成果怎麼呈現？大學校系要看幾件呢？</h5>
				<div class="qa-text">
					<img height="25" :src="content.qa" width="25" />在申請入學招生簡章中，很淸楚載明課程學習成果至多3件，大學更重視學習成果的發展歷程(怎麼做的)、心得(學到什麼)及反思，因此，不一定要交滿3件，有好的1件就足夠了。
					<br>(1)	自111學年度起，審查資料可以從學習歷程檔案產出，大學從審查資料中看到學生高中階段的學習活動、學習動機以及人格特質。大學不是逐項給分，而是綜合性審查，從多項資料中對學系需要的能力與特質進行評分。
					<br>(2)	課程學習成果包括高中課程的作業、作品等其他學習成果。教育部委託國立臺灣大學社會學系辦理「作伙學：學習歷程檔案審議計畫」，相關審議成果(例如課程學習成果的作品呈現六大指引，以及不同類型作品在製作時的注意事項)等精彩回饋已公告在<a href="https://www.108epo.com/" target="_blank">作伙學官網</a>，另「作伙做學檔-課程學習成果呈現建議」手冊電子書也可至官網的「成果展示」下載參考。
				</div>

				<p></p>
				<h5>28.參加大學校系的營隊，對於申請大學校系有沒有幫助？</h5>
				<div class="qa-text">
					<img height="25" :src="content.qa" width="25" />
					<br>(1)	參加營隊只能視為興趣探索及瞭解學群學系特色的方式之一。如果您想瞭解自己的興趣或性向，也可以先求助高中或其他輔導資源，藉由性向測驗獲得未來選填志願之方向。
					<br>(2)	各大學可積極協助高中學生進行興趣探索及瞭解學系特色，但大學若在辦理相關營隊或活動時，明示或暗示高中同學「付費」參加這項營隊或活動，就可以拿到「參與證明」當作學習歷程檔案，或是學會如何製作大學喜歡的備審資料，將來在參加申請入學準備或放入備審資料時，會有利於獲得大學靑睞，這樣不僅會造成大學自己審查自己收費所核發或指導的資料，衍生嚴重「招生倫理」問題，更是違背各大學向來揭示「三重二不：備審資料應重視校內活動」的原則，反而變相吸引或鼓勵學生參加校外營隊或活動，讓備審資料淪為學生比拼資源、蒐集證書的軍備競賽。
					<br>(3)	教育部已函示各大學辦理營隊或活動時，應避免過度連結招生選才或學習歷程檔案等情事，並以非營利、鼓勵參與方式進行，如涉及學習歷程檔案之準備或充實，不得收費，亦不可授予參與證明，以維護招生倫理及招生公信。
					<br>(4)	綜上，建議您如擬參加大學營隊或活動，得以興趣探索或瞭解學系特色為出發點；避免誤以為有助於申請大學校系而支付高額費用參與大學營隊或活動。
				</div>

				<p></p>
				<h5>29.我看到電商平臺刊登販售學習歷程檔案諮詢、代寫商品，且強調由大學教授等審查委員專業認證，真的有助於申請入學嗎？</h5>
				<div class="qa-text">
					<img height="25" :src="content.qa" width="25" />沒有幫助。
					<br>請勿聽信購買電商平臺販售大學招生備審資料(包含學習歷程檔案)諮詢、代寫，以及強調由大學教授等審查委員專業認證等涉虛偽不實之商品，以免受騙上當，說明如下：
					<br>(1)	依大學申請入學招生簡章規定，如考生所繳證件或資料有假借、冒用、偽造、變造或考試舞弊等情事，大學將依規定取消錄取資格、開除學籍或撤銷學位證書及畢業資格，情節重大者函送司法單位審理。
					<br>(2)	鑑於電商平臺刊登銷售備審資料(包含學習歷程檔案)代寫代做之相關服務商品內容，已涉嫌引導考生違反大學法及大學申請入學招生規定；為遏止前述情事，教育部已函請數位發展部評估協助轉知各電商平臺本於社會責任，妥為管理電商平臺刊登相關商品；數位發展部也已函請電商平臺相關公(協)會或公司善盡審查責任並協助宣導所屬會員，不得於電商平臺上架登載有關學習歷程檔案代寫代做相關服務或商品內容，並落實商品上架查核機制，以避免違法商品及服務上架販售。
					<br>(3)	學生於各教育階段學習之課程內涵及其所獲得之能力，均有其眞實存在，因此，招聯會公布「大學針對申請入學備審資料審查參考原則」中，亦強調「重視資料眞實性及學生自主準備」，代寫備審資料(包含學習歷程檔案)不是學生本人自主準備，不屬於學生的眞實能力。大學透過招生專業審查，可以詳實辨別學生備審資料之眞實性，以及學生的獨特性、素養能力、學習過程等。
					<br>(4)	教育部已要求大學教職員不得以個人名義參與或兼職有關營利性質且涉及學習歷程檔案準備或充實之營隊、課程或諮詢工作室等相關活動，藉以收取報酬；並要求大學辦理招生應遵守招生倫理，當年度招生試務或甄試委員亦應落實迴避原則。
				</div>

				<p></p>
				<h5>30.我想利用AI工具(聊天機器人)幫我製作申請入學第二階段備審資料，大學教授如何審查呢？</h5>
				<div class="qa-text">
					<img height="25" :src="content.qa" width="25" />大學在審查資料時相當注重資料眞實性與考生自主準備，考生不宜過度依賴AI工具，若讓審查教授對於審查資料產生抄襲或代寫疑慮，對考生可能有不利的影響。招聯會訂定「因應AI技術發展，大學辦理申請入學招生參考注意事項」，提醒各校系辦理申請入學招生時留意：
					<br>(1)	重申「三重二不」原則：學系審查時，重視的是學生基本素養所展現的核心能力、學生在校內的學習活動、資料眞實性及學生自主準備，學系應秉持前開原則嚴謹、妥善辦理。
					<br>(2)	掌握審查資料重點：審查資料的目的，是透過學生呈現的資料，了解學生高中三年所參與的學習活動與成果，藉此評估其所展現的學習動機、興趣、能力及特質是否適合學系。學系應綜合評量，而非僅以文字表述能力為評分依據。
					<br>(3)	加強其他甄試項目：各校系可透過既有的面試或實作等其他甄試項目確認資料眞實性，且更多面向的了解學生特質與能力，各校系可多加留意學生審查資料內容與其他甄試項目表現間之連結。目前於第二階段指定項目僅辦理審查資料之校系，未來可加強規劃辦理其他甄試項目。
				</div>

				<p></p>
				<h5>31.我在社群軟體(例如：FB或IG)的升學社團中看到網友討論大學考試、招生的訊息，要怎麼確認是正確的資訊呢？</h5>
				<div class="qa-text">
					<img height="25" :src="content.qa" width="25" />
					<br>(一)	大學招生自主，各考招單位及教育部委託計畫團隊分別針對不同受眾及需求辦理相關活動，期以多元形式傳遞正確的升學資訊，以利推動多元入學方案及社會溝通；學生及家長除透過就讀高中所提供的升學相關資訊外，亦得透過參與考招單位辦理的實體活動、宣導文宣手冊、考招單位官方網站及宣導影片等不同方式獲取正確資訊。
					<br>(二)	現行相關宣導作法舉例如下：
					<br>(1)	招聯會會同教育部每年辦理大學多元入學方案全國家長/學生分區說明會(北中南東4區)，相關內容並置於網頁供學生家長參考，您可以上招聯會網站留意活動訊息。
					<br>(2)	持續維護並滾動更新<a href="https://nsdua.moe.edu.tw/" target="_blank">「大學多元入學升學網」</a>，教育部每年編印「新一學年度大學多元入學手冊」發送各高中，供學生家長查閱升學資訊。透過招聯會官方網站發布多元入學方案、考招措施相關新聞稿、宣導摺頁、動畫影片與常見問答等宣導資訊。
					<br>(3)	各項考試相關資訊均於<a href="https://www.ceec.edu.tw/" target="_blank">大考中心官網</a>公吿供學生參考，大考中心line官方帳號於108年10月15日正式上線，內容包含考試說明、報名事項、報名文件、報名繳費、應考事項、違規事項、常見違規、考前叮嚀、大學多元入學諮詢管道等主題選單，學生得多加利用。
 					<br><a target="_blank" href="https://line.me/R/ti/p/%40415tatkb"><img :src="content.official_line" style="width: 100%;"/></a>
				</div>

				<p></p>
				<h5>32.請問我不是108 課綱的學生(如重考生、境外返國、高中肄業等)，所以我沒有學習歷程檔案， 111 學年度起還可以參加那些聯合招生管道？是否會影響評分？</h5>
				<div class="qa-text">
					<img height="25" :src="content.qa" width="25" />申請入學、分發入學招生管道均可報名。另可以參加各校單獨招生的特殊選才管道。沒有學習歷程檔案是否會影響評分，就各管道分別說明如下：
					<br>(1)	申請入學：自 111 學年度起，考生通過第一階段篩選之後，仍可以在申請大學校系時到甄選會網站以PDF檔案方式上傳審查資料。至於審查資料之審查，則由大學依其學術專業及系所選才需求進行審查，如學生有不同教育資歷亦會審酌給予公平、公正之評分。
					<br>(2)	分發入學：僅採計入學考試成績後直接分發，與學習歷程檔案無涉。
				</div>

				<p></p>
				<h5>33.若我有通過申請入學任一校系的第1階段篩選，如何繳交校系規定之審查資料？可直接郵寄紙本給大學嗎？</h5>
				<div class="qa-text">
					<img height="25" :src="content.qa" width="25" />111學年度起，校系如有要求課程學習成果、多元表現之審查項目，考生可自學習歷程中央資料庫中勾選資料檔案後上傳，或自行製作PDF格式檔案後上傳，每一校系「勾選上傳」或「PDF上傳」僅限擇一方式辦理；考生若於學習歷程中央資料庫無資料檔案者，皆以「PDF上傳」方式辦理。
					<br>依簡章規定各大學規定繳交之審查資料，除大學校系另有規定外，一律以網路上傳(勾選)方式繳交至甄選會。
					<br>部分校系針對若干審查資料項目，另訂以郵寄或其他方式繳交者，考生應依其規定另行繳交至該大學；為避免自身權益受損，請務必詳閱該校系分則規定。
				</div>

				<p></p>
				<h5>34.我已通過申請入學第1階段篩選，在參加第2階段指定項目甄試時，大學審查教授可以看到學測成績嗎？ </h5>
				<div class="qa-text">
					<img height="25" :src="content.qa" width="25" />不可以。
					<br>99學年度起，教育部要求各校不提供各學系參加第2階段甄試考生之學測成績，所以在通過第一階段篩選後，進入第二階段指定項目甄試時，大學審查教授無法看到學測成績，僅能針對考生之審查資料或面試等甄試項目評分，考生的甄試總成績是由各校招生單位，依據各系組在招生簡章的校系分則裡所列的成績佔分比例來計算。
				</div>

				<p></p>
				<h5>35.填寫「申請入學」志願時，如果我將備取系組填在前面，正取系組填在後面，而備取沒有獲分發，是否正取系組也無法獲分發？</h5>
				<div class="qa-text">
					<img height="25" :src="content.qa" width="25" />不會。
					<br>填寫志願序時，無論其為正取或備取及其備取序，請依照您自己最想就讀校系的順序填寫，備取的志願校系如果有正取生放棄時，就有機會遞補。如果沒有備取到，仍可分發到正取系組。另提醒您，正取系組志願之後的其他志願序，都是無效的志願序。
				</div>

				<p></p>
				<h5>36.非官方之落點分析可靠嗎？學生需要去做落點分析嗎？</h5>
				<div class="qa-text">
					<img height="25" :src="content.qa" width="25" />落點分析是使用去年校系的錄取分數推估今年校系的錄取分數。但是每年均有部分校系採計之考科數、加重計分方式改變或考生成績分布不同、校系名額異動等差異，落點分析一定會有誤差，不可全信，僅可作為參考。
				</div>

				<p></p>
				<h5>37.各招生管道前一學年度之各系所的錄取級分及篩選科目或加權計分等規定，我該去何處查詢？</h5>
				<div class="qa-text">
					<img height="25" :src="content.qa" width="25" />(1)繁星推薦：請至<a href="https://www.cac.edu.tw" target="_blank">甄選會網站</a>選擇「繁星推薦」進入「歷年資料」點選欲查詢學年度查看「各校系錄取標準一覽表」。
					<br>(2)申請入學：請至<a href="https://www.cac.edu.tw" target="_blank">甄選會網站</a>選擇「申請入學」進入「歷年資料」點選欲查詢學年度查看「各校系篩選標準一覽表」及「各校系最低分發標準一覽表」。
					<br>(3)分發入學：請至<a href="https://www.uac.edu.tw" target="_blank">考分會網站</a>選擇「下載專區」進入「歷年資料下載」點選年度下載「各系組最低錄取分數及錄取人數一覽表」。
				</div>

				<p></p>
				<h5>38.我家中沒有電腦，該如何上傳審查資料及上網選填志願？</h5>
				<div class="qa-text">
					<img height="25" :src="content.qa" width="25" />各高中均開放部分電腦及網路設備提供畢業考生上網上傳審查資料或選填志願，家中沒有電腦或網路的考生可至畢業學校尋求協助。此外，也可到各鄉(鎮、市、區)公所或公立圖書館使用資訊網路設備。
				</div>

				<p></p>
				<h5>39.繁星推薦及申請入學管道中某些校系規定不得轉系，可以考轉學考嗎？</h5>
				<div class="qa-text">
					<img height="25" :src="content.qa" width="25" />繁星推薦及申請入學之錄取生就讀大學期間得否轉系由各大學自行訂定，並在當年度招生簡章中敘明，考生因學習需要得自行參加轉學考試。
				</div>

				<p></p>
				<h5>40.哪些人需要參加分發入學「文件審查」？</h5>
				<div class="qa-text">
					<img height="25" :src="content.qa" width="25" />(1)未通過「93 至 113 學年度學力證明文件審查」，且非學校集體報名之應屆生，須繳交學歷(力)證明文件進行審查，審驗未通過者，不得參與登記分發。
					<br>(2)以特種生身分登記者，須提出特種生身分審查申請，逾期未提出申請或經審查為資格不符者，視為普通生不予加分優待。(特種生類別包含：原住民、退伍軍人、僑生、蒙藏生、派外人員子女、境外優秀科技人才子女)
					<br>(3)聽覺障礙中、重度學生，須繳交身心障礙證明或符合規定之診斷書，經審驗通過者，參與分發時得免檢定英語聽力測驗成績。
				</div>

				<p></p>
				<h5>41.持大陸地區高中學歷(力)證件的學生，參加分發入學「文件審查」時應該繳交什麼樣的文件？</h5>
				<div class="qa-text">
					<img height="25" :src="content.qa" width="25" />大陸地區的高中學歷(力)證件(如畢業證(明)書)，須經大陸公證處公證及財團法人海峽交流基金會驗證，才能參加登記。如已完成前開程序，審查時僅需繳交高中學歷(力)證明(如畢業證書)即可，如為應屆畢業生尚未拿到畢業證書，則可先繳交歷年成績單，並附上切結書，保證將拿到畢業證書並完成前開程序。
				</div>

				<p></p>
				<h5>42.持國外或港澳地區高中學歷(力)證件的學生，參加分發入學學力審查時應該繳交什麼樣的文件？ </h5>
				<div class="qa-text">
					<img height="25" :src="content.qa" width="25" />持國外或港澳地區高中學歷(力)學生，須繳交經我國駐外館處驗證之高中學歷(力) 證明(如畢業證書)及歷年成績單，並提供歷年入出境日期證明及護照(中英文姓名資料頁)作為查證之用。如為應屆畢業生尚未拿到畢業證書及最後一學期成績單者，可先繳交除最後一學期外之歷年成績單及高三在學證明，並附上切結書。
				</div>

				<p></p>
				<h5>43.參加分發入學完成登記志願後，是否可再申請更改？</h5>
				<div class="qa-text">
					<img height="25" :src="content.qa" width="25" />不可以，登記志願以一次為限，即使尚在登記期間亦不可再更改志願，請務必仔細核對志願無誤後再確認送出。
				</div>

				<p></p>
				<h5>44.大學分發入學落榜後，還有其他升學管道嗎？</h5>
				<div class="qa-text">
					<img height="25" :src="content.qa" width="25" />我國大學入學管道多元，除繁星推薦、申請入學及分發入學三大聯合招生管道之外，尚有單獨招生管道可升讀大學，由學校自主規劃筆試、面試、書面審查、術科或實作測驗等方式甄選，免受學測或分科測驗考試成績限制，單獨招生管道如日間學士班單獨招生、大學進修學士班、空中大學。
				</div>

				<p></p>
				<h5>45.持大陸地區高中學歷(力)證件的學生，入學時應該繳交什麼樣的文件？</h5>
				<div class="qa-text">
					<img height="25" :src="content.qa" width="25" />大陸地區的高中學歷，須經大陸公證處公證、財團法人海峽交流基金會驗證，由直轄市、縣市政府行政機關辦理採認或由就讀學校辦理查驗後，送教育部國教署辦理查證及認定，始完成大陸地區學歷採認程序。
					<br>(1)如入學前已完成學歷採認程序者(即學歷已送經直轄市、縣市政府行政機關辦理採認者)：需繳交高中學歷(力)證明(如畢業證書)及教育主管行政機關採認函文影本至就讀學校，就讀學校得直接受理即可，無須再送教育部國教署查驗及認定。
					<br>(2)如入學前尚未完成學歷採認者：請於入學前先完成大陸公證處公證及財團法人海峽交流基金會驗證程序後，需繳交高中學歷(力) 證件(如畢業證書)至就讀學校，由就讀大學辦理查驗後，送教育部國教署辦理查驗及認定。
				</div>

				<p></p>
				<h5>46.持國外或港澳地區高中學歷(力)證件的學生，入學時應該繳交什麼樣的文件？ </h5>
				<div class="qa-text">
					<img height="25" :src="content.qa" width="25" />持國外或港澳地區高中學歷(力)學生，須繳交經我國駐外館處驗證之高中學歷(力) 證明(如畢業證書) 及歷年成績單，並提供歷年入出境日期證明及護照(中英文姓名資料頁)作為查證之用，及大學規定之其他相關文件。若學生係外國人或僑民者，免附入出境證明。
				</div>

				<!--consultation-->

				<p></p>
				<h5>47.什麼是ColleGo!系統？有哪些功能呢？</h5>
				<div class="qa-text">
					<img height="25" :src="content.qa" width="25" />(1) ColleGo! 取自英文College(大學)與Go(前往)組合而成，鼓勵探索、適性、前進。
					<br>(2) ColleGo! 提供「大學選才」、「高中育才」與「焦點話題」三個類別的資訊，內容豐富，特別設計了閱讀資料時需要的各項功能，包括比較功能、搜尋功能及個人化功能等。不論是高一、高二或高三學生，若不熟悉大學校系，也不知道如何選擇校系，ColleGo! 是可以幫上忙的。
				</div>

				<p></p>
				<h5>48.學群、學類、學系是什麼？ </h5>
				<div class="qa-text">
					<img height="25" :src="content.qa" width="25" />ColleGo!可查詢近2000個大學校系，但校系數眾多查詢不易，因此目前ColleGo!取用大考中心之學群學類分類架構，此分類架構依興趣代碼與學習內容，把相近的學系歸為一個學類，將領域相近的學類集結為一個學群。目前本系統已發展132個學類，並計有18個學群，進入ColleGo! 系統，就可以依學群、學類和對應的學系進行查詢與閱讀資訊。
				</div>

				<p></p>
				<h5>49.ColleGo! 「大學選才」中有哪些功能對我認識校系有幫助呢？</h5>
				<div class="qa-text">
					<img height="25" :src="content.qa" width="25" />除了從ColleGo!「大學選才」中的「認識學類」查看「對應校系」之外，還有比較功能和搜尋功能，能幫助你更聚焦在認識校系及選擇校系： 
					<br>(1) 比較功能：可一次比較感興趣的三個學群、學類與學系，迅速掌握不同學群、學類、學系之間差異。
					<br>(2) 搜尋功能：可利用特定關鍵字進行資訊查找，或選擇不同篩選條件進行進階搜尋，取得自己所需要的資料。
				</div>

				<p></p>
				<h5>50.ColleGo!「高中育才」內有什麼內容對我選擇校系有幫助呢？</h5>
				<div class="qa-text">
					<img height="25" :src="content.qa" width="25" />「高中育才」包含五種「探索系列」模組及「三年行動計畫」，其中「探索興趣類型」、「探索領域學科」二個探索模組，以及「三年行動計畫」很適合高三學生使用。
					<br>(1) 探索興趣類型：透過興趣代碼找到可能適合之學群、學類，再從學類往下探索到學系。
					<br>(2) 探索領域學科：選擇自己感興趣或擅長的學科，參考重視該學科的學群、學類，再從學類往下探索到學系。
					<br>(3) 三年行動計畫：選擇高三階段的「升學準備」該項行動類別，裡面有「我的選系決策－學類比較」、「我的選系決策－學系比較」、「所選校系的理由」、「選系放大鏡」等行動能幫助你進行校系選擇。
				</div>

				<p></p>
				<h5>51.	我想知道就讀高中的課程地圖及開設課程，該如何查詢？</h5>
				<div class="qa-text">
					<img height="25" :src="content.qa" width="25" />教育部國民及學前教育署已建置<a href="https://course.k12ea.gov.tw/" target="_blank">「全國高級中等學校課程計畫平臺」</a>羅列各所高中的基本資料、學校特色及課程地圖，讓學生與家長深入了解就讀的高中，協助學生探索生涯發展方向。在課程地圖上可以看到：
					<br>(1)學校針對不同班別，在不同年級的課程規劃。
					<br>(2)不同課程類型(部定必修、校訂必修、選修)開設的課程。
					<br>(3)學校的課程計畫，學校的基本資料、願景與學生圖像、學校特色項目、課程發展與規劃、團體活動時間及彈性學習時間的實施規劃、學校選課輔導流程機制等，以幫助學生認識高中三年的課程規劃。若擬進一步瞭解就讀高中之課程規劃，可直接洽詢就讀高中。
				</div>


				<!-- last -->
				<p></p>
			</div>
		</div>
	</div>
</template>

<script>
export default {
    data() {
        return {
			content: {
                qa: require('page/_home/img/carousel/qa.png'),
                official_line: require('page/_home/img/official_line.png')
            },
        }
    },
}
</script>