var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page_admin_addNews" },
    [
      _c(
        "v-container",
        [
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            [
              _c(
                "v-flex",
                { attrs: { md8: "" } },
                [
                  _c(
                    "v-form",
                    { ref: "form" },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "標題",
                          "prepend-icon": "title",
                          rules: [
                            function (v) {
                              return !!v || "請輸入標題"
                            },
                          ],
                        },
                        model: {
                          value: _vm.title,
                          callback: function ($$v) {
                            _vm.title = $$v
                          },
                          expression: "title",
                        },
                      }),
                      _vm._v(" "),
                      _c("v-text-field", {
                        attrs: { label: "連結", "prepend-icon": "link" },
                        model: {
                          value: _vm.link,
                          callback: function ($$v) {
                            _vm.link = $$v
                          },
                          expression: "link",
                        },
                      }),
                      _vm._v(" "),
                      _c("v-select", {
                        attrs: {
                          items: _vm.announcers,
                          label: "來源",
                          "prepend-icon": "rss_feed",
                          "min-width": "300px",
                          "offset-y": "",
                        },
                        model: {
                          value: _vm.announcer,
                          callback: function ($$v) {
                            _vm.announcer = $$v
                          },
                          expression: "announcer",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "v-menu",
                        {
                          ref: "menu",
                          attrs: {
                            transition: "scale-transition",
                            "offset-y": "",
                            "min-width": "290px",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function (ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "v-text-field",
                                    _vm._g(
                                      {
                                        attrs: {
                                          label: "發布日期",
                                          "prepend-icon": "event",
                                          readonly: "",
                                        },
                                        model: {
                                          value: _vm.date,
                                          callback: function ($$v) {
                                            _vm.date = $$v
                                          },
                                          expression: "date",
                                        },
                                      },
                                      on
                                    )
                                  ),
                                ]
                              },
                            },
                          ]),
                          model: {
                            value: _vm.datePicker,
                            callback: function ($$v) {
                              _vm.datePicker = $$v
                            },
                            expression: "datePicker",
                          },
                        },
                        [
                          _vm._v(" "),
                          _c("v-date-picker", {
                            attrs: { locale: "zh-tw", scrollable: "" },
                            on: {
                              input: function ($event) {
                                _vm.datePicker = false
                              },
                            },
                            model: {
                              value: _vm.date,
                              callback: function ($$v) {
                                _vm.date = $$v
                              },
                              expression: "date",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-card-actions",
                        [
                          _c("v-spacer"),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary" },
                              on: { click: _vm.submit },
                            },
                            [_vm._v("發布")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-snackbar",
                {
                  attrs: { timeout: _vm.timeout },
                  model: {
                    value: _vm.snackbar,
                    callback: function ($$v) {
                      _vm.snackbar = $$v
                    },
                    expression: "snackbar",
                  },
                },
                [
                  _vm._v("\r\n                發布成功\r\n                "),
                  _c(
                    "v-btn",
                    {
                      attrs: { flat: "" },
                      on: {
                        click: function ($event) {
                          _vm.snackbar = false
                        },
                      },
                    },
                    [_vm._v("OK")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }