var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page_home" }, [
    _c("div", { staticClass: "container" }, [
      _c(
        "header",
        [
          _c("div", { staticClass: "top-bar" }, [
            _c("div", { staticClass: "container-fluid" }, [
              _c("div", { staticClass: "row" }, [
                _vm._m(0),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-sm-6 d-flex justify-content-center" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "v-btn--flat",
                        attrs: { tag: "v-btn", to: "/service1" },
                      },
                      [_vm._v("諮詢管道")]
                    ),
                    _vm._v(" "),
                    _c(
                      "router-link",
                      {
                        staticClass: "v-btn--flat",
                        attrs: { tag: "v-btn", flat: "", to: "/download" },
                      },
                      [_vm._v("資料下載")]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "v-toolbar",
            {
              staticClass: "hidden-sm-and-down",
              attrs: { color: "blue darken-3" },
            },
            [
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-toolbar-items",
                [
                  _vm._l(_vm.navItems, function (item) {
                    return [
                      item.children
                        ? _c(
                            "v-menu",
                            {
                              key: item.name,
                              attrs: { "offset-y": "", "open-on-hover": "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function (ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            {
                                              attrs: { to: item.to, flat: "" },
                                            },
                                            on
                                          ),
                                          [_vm._v(_vm._s(item.name))]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [
                              _vm._v(" "),
                              _c(
                                "v-list",
                                _vm._l(item.children, function (item) {
                                  return _c(
                                    "v-list-tile",
                                    {
                                      directives: [
                                        { name: "ripple", rawName: "v-ripple" },
                                      ],
                                      key: item.to,
                                      attrs: { to: item.to },
                                    },
                                    [
                                      _vm._v(
                                        "\r\n                            " +
                                          _vm._s(item.name) +
                                          "\r\n                        "
                                      ),
                                    ]
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          )
                        : _c(
                            "v-btn",
                            { key: item.to, attrs: { to: item.to, flat: "" } },
                            [_vm._v(_vm._s(item.name))]
                          ),
                    ]
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _c("v-spacer"),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "nav",
            {
              staticClass:
                "hidden-md-and-up navbar fixed-top navbar-expand-lg navbar-dark bg-dark fixed-top",
            },
            [
              _c("div", { staticClass: "container" }, [
                _c(
                  "button",
                  {
                    staticClass: "navbar-toggler navbar-toggler-right",
                    attrs: {
                      type: "button",
                      "data-toggle": "collapse",
                      "data-target": "#navbarResponsive",
                      "aria-controls": "navbarResponsive",
                      "aria-expanded": "false",
                      "aria-label": "Toggle navigation",
                    },
                  },
                  [
                    _c(
                      "span",
                      { staticClass: "navbar-toggler-icon" },
                      [
                        _c("font", { attrs: { color: "#ffffff" } }, [
                          _vm._v("☰"),
                        ]),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "collapse navbar-collapse",
                    attrs: { id: "navbarResponsive" },
                  },
                  [
                    _c("ul", { staticClass: "navbar-nav ml-auto" }, [
                      _c(
                        "li",
                        { staticClass: "nav-item" },
                        [
                          _c(
                            "router-link",
                            { staticClass: "nav-link", attrs: { to: "/" } },
                            [_vm._v("首頁")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        { staticClass: "nav-item" },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "nav-link",
                              attrs: { to: "/about" },
                            },
                            [_vm._v("理念與精神")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("li", { staticClass: "nav-item dropdown" }, [
                        _c(
                          "a",
                          {
                            staticClass: "nav-link dropdown-toggle",
                            attrs: {
                              href: "#",
                              id: "navbarDropdownPortfolio",
                              "data-toggle": "dropdown",
                              "aria-haspopup": "true",
                              "aria-expanded": "false",
                            },
                          },
                          [
                            _vm._v(
                              "\r\n                                考試方式\r\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "dropdown-menu dropdown-menu-right",
                            attrs: {
                              "aria-labelledby": "navbarDropdownPortfolio",
                            },
                          },
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "dropdown-item",
                                attrs: { to: "/examinations/exam-1" },
                              },
                              [_vm._v("學科能力測驗")]
                            ),
                            _vm._v(" "),
                            _c(
                              "router-link",
                              {
                                staticClass: "dropdown-item",
                                attrs: { to: "/examinations/exam-2" },
                              },
                              [_vm._v("分科測驗")]
                            ),
                            _vm._v(" "),
                            _c(
                              "router-link",
                              {
                                staticClass: "dropdown-item",
                                attrs: { to: "/examinations/exam-3" },
                              },
                              [_vm._v("術科考試")]
                            ),
                            _vm._v(" "),
                            _c(
                              "router-link",
                              {
                                staticClass: "dropdown-item",
                                attrs: { to: "/examinations/exam-4" },
                              },
                              [_vm._v("高中英文聽力測驗")]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("li", { staticClass: "nav-item dropdown" }, [
                        _c(
                          "a",
                          {
                            staticClass: "nav-link dropdown-toggle",
                            attrs: {
                              href: "#",
                              id: "navbarDropdownPortfolio",
                              "data-toggle": "dropdown",
                              "aria-haspopup": "true",
                              "aria-expanded": "false",
                            },
                          },
                          [
                            _vm._v(
                              "\r\n                                入學管道\r\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "dropdown-menu dropdown-menu-right",
                            attrs: {
                              "aria-labelledby": "navbarDropdownPortfolio",
                            },
                          },
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "dropdown-item",
                                attrs: { to: "/admissions/admission-1" },
                              },
                              [_vm._v("繁星推薦")]
                            ),
                            _vm._v(" "),
                            _c(
                              "router-link",
                              {
                                staticClass: "dropdown-item",
                                attrs: { to: "/admissions/admission-2" },
                              },
                              [_vm._v("申請入學")]
                            ),
                            _vm._v(" "),
                            _c(
                              "router-link",
                              {
                                staticClass: "dropdown-item",
                                attrs: { to: "/admissions/admission-3" },
                              },
                              [_vm._v("分發入學")]
                            ),
                            _vm._v(" "),
                            _c(
                              "router-link",
                              {
                                staticClass: "dropdown-item",
                                attrs: { to: "/admissions/admission-4" },
                              },
                              [_vm._v("特殊選才")]
                            ),
                            _vm._v(" "),
                            _c(
                              "router-link",
                              {
                                staticClass: "dropdown-item",
                                attrs: { to: "/admissions/admission-5" },
                              },
                              [_vm._v("單獨招生")]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "li",
                        { staticClass: "nav-item" },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "nav-link",
                              attrs: { to: "/relations" },
                            },
                            [_vm._v("考試與入學管道")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        { staticClass: "nav-item" },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "nav-link",
                              attrs: { to: "/schedule" },
                            },
                            [_vm._v("重要日程表")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("li", { staticClass: "nav-item dropdown" }, [
                        _c(
                          "a",
                          {
                            staticClass: "nav-link dropdown-toggle",
                            attrs: {
                              href: "#",
                              id: "navbarDropdownPortfolio",
                              "data-toggle": "dropdown",
                              "aria-haspopup": "true",
                              "aria-expanded": "false",
                            },
                          },
                          [
                            _vm._v(
                              "\r\n                                114 考招調整\r\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "dropdown-menu dropdown-menu-right",
                            attrs: {
                              "aria-labelledby": "navbarDropdownPortfolio",
                            },
                          },
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "dropdown-item",
                                attrs: { to: "/revision/revision-1" },
                              },
                              [_vm._v("招生調整")]
                            ),
                            _vm._v(" "),
                            _c(
                              "router-link",
                              {
                                staticClass: "dropdown-item",
                                attrs: { to: "/revision/revision-2" },
                              },
                              [_vm._v("考試調整")]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("li", { staticClass: "nav-item dropdown" }, [
                        _c(
                          "a",
                          {
                            staticClass: "nav-link dropdown-toggle",
                            attrs: {
                              href: "/qa",
                              id: "navbarDropdownPortfolio",
                              "data-toggle": "dropdown",
                              "aria-haspopup": "true",
                              "aria-expanded": "false",
                            },
                          },
                          [
                            _vm._v(
                              "\r\n                                常見問題\r\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "dropdown-menu dropdown-menu-right",
                            attrs: {
                              "aria-labelledby": "navbarDropdownPortfolio",
                            },
                          },
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "dropdown-item",
                                attrs: { to: "/qa1" },
                              },
                              [_vm._v("考試")]
                            ),
                            _vm._v(" "),
                            _c(
                              "router-link",
                              {
                                staticClass: "dropdown-item",
                                attrs: { to: "/qa2" },
                              },
                              [_vm._v("招生")]
                            ),
                            _vm._v(" "),
                            _c(
                              "router-link",
                              {
                                staticClass: "dropdown-item",
                                attrs: { to: "/qa3" },
                              },
                              [_vm._v("諮詢")]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]
                ),
              ]),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "page_container" }, [
        _c(
          "div",
          { staticClass: "row" },
          [
            _c("router-view"),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-4" }, [
              _c("div", { staticClass: "card my-4" }, [
                _c("h5", { staticClass: "card-header" }, [
                  _vm._v("多元入學秘笈"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "card-body" }, [
                  _c("ul", { staticClass: "list-unstyled mb-0" }, [
                    _c(
                      "li",
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "v-btn--flat",
                            attrs: { tag: "v-btn", to: "/calendar" },
                          },
                          [_vm._v("114 學年度行事曆")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "li",
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "v-btn--flat",
                            attrs: { tag: "v-btn", to: "/note" },
                          },
                          [_vm._v("重要叮嚀事項")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "li",
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "v-btn--flat",
                            attrs: { tag: "v-btn", to: "/plan" },
                          },
                          [_vm._v("入學方案簡表")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "li",
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "v-btn--flat",
                            attrs: { tag: "v-btn", to: "/flowchart" },
                          },
                          [_vm._v("入學方案架構圖")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "li",
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "v-btn--flat",
                            attrs: { tag: "v-btn", to: "/agency" },
                          },
                          [_vm._v("考試及招生單位")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "card my-4" }, [
                _c("h5", { staticClass: "card-header" }, [
                  _vm._v("特殊選才招生訊息"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "card-body" }, [
                  _c("ul", { staticClass: "list-unstyled mb-0" }, [
                    _c(
                      "li",
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "v-btn--flat",
                            attrs: { tag: "v-btn", to: "/srecruit-1" },
                          },
                          [_vm._v("特殊選才招生管道")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "li",
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              flat: "",
                              href: "https://srecruit.moe.edu.tw/",
                              target: "_blank",
                            },
                          },
                          [_vm._v("特殊選才招生專區")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "card my-4" }, [
                _c("h5", { staticClass: "card-header" }, [
                  _vm._v("考試簡章公告連結"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "card-body" }, [
                  _c("ul", { staticClass: "list-unstyled mb-0" }, [
                    _c(
                      "li",
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              flat: "",
                              href: "https://www.ceec.edu.tw/",
                              target: "_blank",
                            },
                          },
                          [_vm._v("英聽、學測、分科測驗")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "li",
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              flat: "",
                              href: "https://www.cape.edu.tw/",
                              target: "_blank",
                            },
                          },
                          [_vm._v("術科考試")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "card my-4" }, [
                _c("h5", { staticClass: "card-header" }, [
                  _vm._v("招生簡章公告連結"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "card-body" }, [
                  _c("ul", { staticClass: "list-unstyled mb-0" }, [
                    _c(
                      "li",
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              flat: "",
                              href: "https://www.cac.edu.tw/star114/index.php",
                              target: "_blank",
                            },
                          },
                          [_vm._v("繁星推薦")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "li",
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              flat: "",
                              href: "https://www.cac.edu.tw/apply114/index.php",
                              target: "_blank",
                            },
                          },
                          [_vm._v("申請入學")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "li",
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              flat: "",
                              href: "https://www.uac.edu.tw/synopsis.htm",
                              target: "_blank",
                            },
                          },
                          [_vm._v("分發入學")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "card my-4" }, [
                _c("h5", { staticClass: "card-header" }, [
                  _vm._v("分發入學登記志願"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "card-body" }, [
                  _c("ul", { staticClass: "list-unstyled mb-0" }, [
                    _c(
                      "li",
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              flat: "",
                              href: "https://www.uac.edu.tw/113data/113single_web/",
                              target: "_blank",
                            },
                          },
                          [_vm._v("登記志願單機版")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "li",
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              flat: "",
                              href: "https://www.youtube.com/watch?v=GJAftCswQoU",
                              target: "_blank",
                            },
                          },
                          [_vm._v("登記志願教學影片")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "card my-4" }, [
                _c("h5", { staticClass: "card-header" }, [
                  _vm._v("ColleGo!系統"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "card-body" }, [
                  _c("ul", { staticClass: "list-unstyled mb-0" }, [
                    _c(
                      "li",
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "v-btn--flat text-none",
                            attrs: { tag: "v-btn", to: "/collego" },
                          },
                          [_vm._v("ColleGo!系統簡介")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "li",
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "text-none",
                            attrs: {
                              flat: "",
                              href: "https://collego.edu.tw/",
                              target: "_blank",
                            },
                          },
                          [_vm._v("ColleGo!系統連結")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "card my-4" }, [
                _c("h5", { staticClass: "card-header" }, [_vm._v("好站連結")]),
                _vm._v(" "),
                _c("div", { staticClass: "card-body" }, [
                  _c("ul", { staticClass: "list-unstyled mb-0" }, [
                    _c(
                      "li",
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              flat: "",
                              href: "https://www.edu.tw/",
                              target: "_blank",
                            },
                          },
                          [_vm._v("教育部網站")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "li",
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              flat: "",
                              href: "https://www.edu.tw/helpdreams/",
                              target: "_blank",
                            },
                          },
                          [_vm._v("圓夢助學網")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "li",
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              flat: "",
                              href: "https://www.techadmi.edu.tw/",
                              target: "_blank",
                            },
                          },
                          [_vm._v("技專校院招策會")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ]),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _vm._m(1),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-sm-6" }, [
      _c("a", { staticClass: "logo", attrs: { href: "/" } }, [
        _c("img", {
          attrs: { src: require("page/_home/img/nsdua-logo.png"), alt: "logo" },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("footer", { staticClass: "py-5 bg-dark" }, [
      _c("div", { staticClass: "container" }, [
        _c("p", { staticClass: "m-0 text-center text-white" }, [
          _vm._v("Copyright © 大學多元入學升學網 2024"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }