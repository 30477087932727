var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page_home_other col-lg-8 mb-8" },
    [
      _c(
        "v-chip",
        {
          staticClass: "font-size:3rem;",
          attrs: { "text-color": "white", color: "primary" },
        },
        [_vm._v(_vm._s(_vm.content[_vm.$route.path].name))]
      ),
      _vm._v(" "),
      _c("br"),
      _c("br"),
      _vm._v(" "),
      _vm._l(_vm.content[_vm.$route.path].pics, function (pic) {
        return _c("div", { key: pic.name }, [
          _c("img", { attrs: { src: pic, width: "100%" } }),
        ])
      }),
      _vm._v(" "),
      _c("div", {
        domProps: { innerHTML: _vm._s(_vm.content[_vm.$route.path].text) },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }