var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page_home_news col-lg-8 mb-8" },
    [
      _c(
        "v-carousel",
        { attrs: { "hide-delimiters": "", height: "auto" } },
        _vm._l(_vm.carousel, function (img, i) {
          return _c("v-carousel-item", { key: i, attrs: { src: img.src } })
        }),
        1
      ),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c("v-pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.news[0],
            expression: "news[0]",
          },
        ],
        attrs: { length: _vm.news.length },
        model: {
          value: _vm.page,
          callback: function ($$v) {
            _vm.page = $$v
          },
          expression: "page",
        },
      }),
      _vm._v(" "),
      _c("table", { staticClass: "table table-responsive" }, [
        _c(
          "tbody",
          _vm._l(_vm.news[_vm.page - 1], function (item) {
            return _c("tr", { key: item.title }, [
              _c("th", { attrs: { scope: "row" } }, [
                _c("h4", [
                  item.announcer == "ceec"
                    ? _c("span", { staticClass: "badge badge-primary" }, [
                        _vm._v("大考中心"),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  item.announcer == "cac"
                    ? _c("span", { staticClass: "badge badge-info" }, [
                        _vm._v("甄委會"),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  item.announcer == "uac"
                    ? _c("span", { staticClass: "badge badge-warning" }, [
                        _vm._v("考分會"),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  item.announcer == "jbcrc"
                    ? _c("span", { staticClass: "badge badge-success" }, [
                        _vm._v("招聯會"),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  item.announcer == "cape"
                    ? _c("span", { staticClass: "badge badge-secondary" }, [
                        _vm._v("術聯會"),
                      ])
                    : _vm._e(),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "td",
                [
                  _vm._l(item.type, function (type, index) {
                    return _c(
                      "v-chip",
                      {
                        key: index,
                        attrs: {
                          small: "",
                          "text-color": "white",
                          color: "primary",
                        },
                      },
                      [_vm._v(_vm._s(type))]
                    )
                  }),
                  _vm._v(" "),
                  item.type[0] ? _c("br") : _vm._e(),
                  _vm._v(" "),
                  _vm._l(item.titles, function (t) {
                    return _c(
                      "a",
                      {
                        key: t.title,
                        attrs: {
                          href: t.link == "" ? null : t.link,
                          target: "_blank",
                        },
                      },
                      [
                        _vm._v(
                          "\r\n                        " +
                            _vm._s(t.title) +
                            "\r\n                    "
                        ),
                      ]
                    )
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _c("td", { staticClass: "time" }, [
                _vm._v(_vm._s(item.date.slice(0, 10))),
              ]),
            ])
          }),
          0
        ),
      ]),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _vm._m(1),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", { staticClass: "mt-4 mb-3" }, [
      _vm._v("最新消息\r\n        "),
      _c("small", [_vm._v("News")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card mb-4" }, [
      _c("div", { staticClass: "card-body reminder" }, [
        _c("h2", { staticClass: "card-title" }, [_vm._v("重要提醒")]),
        _vm._v(" "),
        _c("p", { staticClass: "card-text" }, [
          _c("ol", [
            _c("li", [
              _c("p", [
                _vm._v(
                  "\r\n                            1.\t經「114學年度大學辦理特殊選才招生計畫」( 以下簡稱特殊選才) 錄取且完成報到之考生，未於錄取學校規定之期限內放棄錄取資格者，一律不得參加114學年度大學繁星推薦招生( 以下簡稱繁星推薦)、大學申請入學招生( 以下簡稱申請入學)、大學分發入學招生( 以下簡稱分發入學)、科技校院繁星計畫聯合推薦甄選入學、科技校院四年制及專科學校二年制特殊選才入學聯合招生、科技校院日間部四年制申請入學聯合招生、四技二專之技優保送入學、技優甄審入學、甄選入學與日間部聯合登記分發入學。\r\n                        "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("li", [
              _c("p", [
                _vm._v(
                  "\r\n                            2.\t報名參加大學「繁星推薦」及「申請入學」招生之每一考生，皆應至甄選會網址(https://www.cac.edu.tw/)，自行設定個人專屬之密碼。本組密碼係為報名、錄取結果(或篩選、分發結果)查詢、上傳審查資料、登記志願序、網路聲明放棄入學資格作業等系統所需輸入的證號，請妥善保管，切勿將密碼吿知他人。\r\n                        "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("li", [
              _c("p", [
                _vm._v(
                  "\r\n                            3.\t考生已報名「繁星推薦」者不得重複報名「科技校院繁星計畫」；「繁星推薦」第1∼7類學群錄取生，無論放棄與否，一律不得報名「申請入學」，亦不得參加「科技校院日間部四年制申請入學」第一階段篩選。「繁星推薦」第8類學群通過篩選學生不得報名「申請入學」同一大學之同一校系，「繁星推薦」第8類學群錄取生不得參加「申請入學」統一分發。\r\n                        "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("li", [
              _c("p", [
                _vm._v(
                  "\r\n                            4.\t考生通過「申請入學」第一階段篩選後，務必記得參閱招生簡章各大學第二階段指定項目甄試之規定，或上網參閱校系公告之相關訊息。第二階段指定項目甄試，各大學校系規定繳交之審查資料，除另有規定外，一律以網路上傳(勾選)方式繳交至甄選會。校系如有要求課程學習成果、多元表現之審查項目，考生可自高級中等教育階段學生學習歷程資料庫(以下簡稱學習歷程中央資料庫)中勾選資料檔案後上傳，或自行製作PDF格式檔案後上傳，每一校系「勾選上傳」或「PDF上傳」僅限擇一方式辦理；考生若於學習歷程中央資料庫無資料檔案者，皆以「PDF上傳」方式辦理。\r\n                        "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("li", [
              _c("p", [
                _vm._v(
                  "\r\n                            5.\t「申請入學」正備取生、「114學年度離島地區及原住民籍高級中等學校應屆畢業生升學國(市) 立師範及教育大學聯合保送甄試」(以下簡稱師資保送) 正備取生及「114學年度原住民族及離島地區醫事人員養成計畫公費生甄試」(以下簡稱醫事保送)正備取生，應同時於114/6/5~6/6至「甄選會」網站登記就讀志願序，未上網登記視同放棄錄取資格。考生需在期間內完成上網選填志願序，且務必將志願表存檔或列印，作為完成登記之憑據。「繁星推薦」第8類學群錄取者，無論放棄與否，一律不得再參加「申請入學」網路就讀志願序登記。\r\n                        "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("li", [
              _c("p", [
                _vm._v(
                  "\r\n                            6.\t「申請入學」未獲分發錄取欲參加「分發入學」的考生，務必把握時間於114/6/17前向大考中心報名分科測驗。\r\n                        "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("li", [
              _c("p", [
                _vm._v(
                  "\r\n                            7.\t參加分發入學招生之考生除下列兩者外，均須於114/6/20 前上傳學力證明文件至考分會，經審查通過後，始可參與分發：（1）由學校（不含非學校型態實驗教育機構）集體報名分科測驗之應屆畢業生。（2）曾於93-113學年度參與分發入學並通過「學力證明文件審查」之考生。\r\n                        "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("li", [
              _c("p", [
                _vm._v(
                  "\r\n                            8.\t原住民等特種生欲申請加分優待者，均須於114/6/20前向考分會提出特種生審查申請，經審查通過後，始可享有加分優待。\r\n                        "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("li", [
              _c("p", [
                _vm._v(
                  "\r\n                            9.\t所有考生（含術科音樂組考生）均須報名至少1科分科測驗，始得參與分發入學。考生可參考分發入學校系分則，依校系規定選擇報考科目。\r\n                        "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("li", [
              _c("p", [
                _vm._v(
                  "\r\n                            10.\t分發入學採網路登記志願，考生可向考分會購買「114 學年度大學分發入學登記相關資訊」參考，於繳交登記費（114/7/29 上午9:00~8/4 中午12:00）後，才能至考分會網站「登記分發志願系統」設定通行碼並選填志願。考生務必妥善保管通行碼及相關個人資料，以防被冒用。\r\n                        "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("li", [
              _c("p", [
                _vm._v(
                  "\r\n                            11.\t分發入學選填志願時間為四天（114/8/1 上午9:00~8/4 下午4:30），一定要在期間內至考分會網站選填並送出志願，並將考分會回傳之志願表存檔或列印，作為完成登記之憑據。\r\n                        "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("li", [
              _c("p", [
                _vm._v(
                  "\r\n                            ※溫馨提醒：報名各項考試或招生前，應詳閱各考試或招生簡章之規定。\r\n                        "
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }