var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page_home_download col-lg-8 mb-8" },
    [
      _c(
        "v-chip",
        {
          staticClass: "font-size:3rem;",
          attrs: { "text-color": "white", color: "primary" },
        },
        [_vm._v("常見問題")]
      ),
      _vm._v(" "),
      _c("br"),
      _c("br"),
      _vm._v(" "),
      _c("div", { staticClass: "card mb-4" }, [
        _c("div", { staticClass: "card-body reminder" }, [
          _c("h2", { staticClass: "card-title" }, [_vm._v("考試")]),
          _vm._v(" "),
          _c("p", { staticClass: "card-text" }),
          _vm._v(" "),
          _c("p"),
          _vm._v(" "),
          _c("h5", [_vm._v("1.我一定要參加學測嗎？")]),
          _vm._v(" "),
          _c("div", { staticClass: "qa-text" }, [
            _c("img", {
              attrs: { height: "25", src: _vm.content.qa, width: "25" },
            }),
            _vm._v(
              "視選擇的入學管道及校系而定，繁星推薦及申請入學除術科校系可能不參採外，餘均參採學測成績。 111 學年度起分發入學管道，大學校系除可以學測成績作為檢定外，亦可採計學測成績，考生應於學測報名前，確認有意報考的校系是否使用學測成績。\n\t\t\t"
            ),
          ]),
          _vm._v(" "),
          _c("p"),
          _vm._v(" "),
          _c("h5", [_vm._v("2.我要如何選擇學測考試的考科？")]),
          _vm._v(" "),
          _c("div", { staticClass: "qa-text" }, [
            _c("img", {
              attrs: { height: "25", src: _vm.content.qa, width: "25" },
            }),
            _vm._v(
              "視您選擇的校系而定。繁星推薦使用學測成績作為檢定、分發比序項目，申請入學使用學測成績作為第一階段的檢定、倍率篩選及同級分超額篩選比序、第二階段的成績採計，分發入學則可將學測成績作為檢定或採計。請先參考各招生簡章之校系分則，查明您有意報名、就讀的校系使用哪些學測科目作為檢定、倍率篩選、採計及分發比序，就會知道您需要報考哪些學測考科。各入學管道會在當學年度學測報名前公告校系參採之學測科目：\n\t\t\t\t"
            ),
            _c("br"),
            _vm._v("(1)\t繁星推薦：請至"),
            _c(
              "a",
              { attrs: { href: "https://www.cac.edu.tw", target: "_blank" } },
              [_vm._v("甄選會網站")]
            ),
            _vm._v(
              "選擇「繁星推薦」進入「訊息公告」或「校系分則查詢」\n\t\t\t\t"
            ),
            _c("br"),
            _vm._v("(2)\t申請入學：請至"),
            _c(
              "a",
              { attrs: { href: "https://www.cac.edu.tw", target: "_blank" } },
              [_vm._v("甄選會")]
            ),
            _vm._v(
              "選擇「申請入學」進入「訊息公告」或「校系分則查詢」\n\t\t\t\t"
            ),
            _c("br"),
            _vm._v("(3)\t分發入學：請至"),
            _c(
              "a",
              { attrs: { href: "https://www.uac.edu.tw", target: "_blank" } },
              [_vm._v("考分會網站")]
            ),
            _vm._v("選擇「最新消息」或「校系分則」。\n\t\t\t"),
          ]),
          _vm._v(" "),
          _c("p"),
          _vm._v(" "),
          _c("h5", [
            _vm._v("3.如果學測有一科為零級分，可以參加繁星推薦及申請入學嗎？"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "qa-text" }, [
            _c("img", {
              attrs: { height: "25", src: _vm.content.qa, width: "25" },
            }),
            _vm._v(
              "自 107 學年度起，取消考生若有學測任一科為零級分不得參加分發比序/第一階段篩選之規定。只要考生有意報名的校系所使用到的學測科目級分加總後不為零級分就可以參加該管道招生，但是若校系訂為檢定之學測科目為零分的話，將無法通過檢定，也就無法參加繁星推薦分發比序或申請入學第一階段之倍率篩選。\n\t\t\t"
            ),
          ]),
          _vm._v(" "),
          _c("p"),
          _vm._v(" "),
          _c("h5", [_vm._v("4.學測的成績可以保留到明年使用嗎？")]),
          _vm._v(" "),
          _c("div", { staticClass: "qa-text" }, [
            _c("img", {
              attrs: { height: "25", src: _vm.content.qa, width: "25" },
            }),
            _vm._v("不可以。\n\t\t\t\t"),
            _c("br"),
            _vm._v(
              "學科能力測驗成績只能在當學年度的入學管道中使用，並不能跨年度使用。「學科能力測驗成績保留五年」的意思是指大考中心為考生的學測成績保留五年，五年之中如果考生有需要，可向大考中心申請成績證明。\n\t\t\t\t"
            ),
            _c("br"),
            _vm._v(
              "教育部為鼓勵學生先累積社會歷練，多元探索志趣，推動「青年教育與就業儲蓄帳戶方案」，並配合提供更為暢通與彈性的就學管道，著重在學生參與職場、學習及國際體驗的資歷，使青年於計畫執行完成後，可繼續接軌升學。爰如您參加「青年教育與就業儲蓄帳戶方案」且計畫執行完成後，擬繼續接軌升學得於規定期限內，持「原畢業年度學科能力測驗成績」，參加申請入學之青年儲蓄帳戶組招生。(有關「青年教育與就業儲蓄帳戶方案」相關事宜，得至"
            ),
            _c(
              "a",
              { attrs: { href: "https://www.edu.tw/1013/", target: "_blank" } },
              [_vm._v("本部青年教育與就業儲蓄帳戶專區")]
            ),
            _vm._v(
              "網頁查詢或逕洽青年教育與就業儲蓄帳戶專案辦公室(電話: (02)7736-5422))。\n\t\t\t"
            ),
          ]),
          _vm._v(" "),
          _c("p"),
          _vm._v(" "),
          _c("h5", [
            _vm._v(
              "5.如果沒有參加學測，只參加分科測驗，是否可以參加「分發入學」招生管道？"
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "qa-text" }, [
            _c("img", {
              attrs: { height: "25", src: _vm.content.qa, width: "25" },
            }),
            _vm._v(
              "可以，但只能選擇「分發入學」管道無需參採學測之校系。相關資訊可至"
            ),
            _c(
              "a",
              { attrs: { href: "https://www.uac.edu.tw", target: "_blank" } },
              [_vm._v("考分會網站")]
            ),
            _vm._v("選擇「校系分則」查詢。\n\t\t\t"),
          ]),
          _vm._v(" "),
          _c("p"),
          _vm._v(" "),
          _c("h5", [_vm._v("6.我一定要參加英聽或術科考試嗎？ ")]),
          _vm._v(" "),
          _c("div", { staticClass: "qa-text" }, [
            _c("img", {
              attrs: { height: "25", src: _vm.content.qa, width: "25" },
            }),
            _vm._v(
              "視您選擇的入學管道而定。各入學管道會在當學年度英聽或術科考試報名前，公告檢定英聽的校系及參加術科考試的校系，考生可查閱當學年度繁星推薦、申請入學及分發入學3本招生簡章或直接上各入學管道網頁查詢，視自己欲報考的校系是否採計英聽或術科，來決定要不要參加英聽測驗或術科考試。\n\t\t\t"
            ),
          ]),
          _vm._v(" "),
          _c("p"),
          _vm._v(" "),
          _c("h5", [_vm._v("7.我要如何選擇分科測驗的考科？ ")]),
          _vm._v(" "),
          _c("div", { staticClass: "qa-text" }, [
            _c("img", {
              attrs: { height: "25", src: _vm.content.qa, width: "25" },
            }),
            _vm._v(
              "如果您要報名的A學系採計分科測驗的物理、化學，您就一定要選考物理、化學。假設只選考化學，就會因「任一採計科目未報考」而無法分發A學系。\n\t\t\t"
            ),
          ]),
          _vm._v(" "),
          _c("p"),
          _vm._v(" "),
          _c("h5", [
            _vm._v(
              "8.學測、分科考試或術科單項若有1科為零分，可以參加分發入學登記分發嗎？"
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "qa-text" }, [
            _c("img", {
              attrs: { height: "25", src: _vm.content.qa, width: "25" },
            }),
            _vm._v("可以，但仍需要視您選擇的校系而定。\n\t\t\t\t"),
            _c("br"),
            _vm._v(
              "若您擇定的校系所檢定的學測科目為零分，將無法通過檢定，無法參加分發。若分科測驗有1科目為零分的話，可以參加分發，但考科組合未加權之級分和須達到最低登記標準，才可以選填採計該組合的校系。另外，部分採計術科之校系，設有術科各單項0分不予分發的限制。詳見 114學年度大學分發入學招生簡章「貳、校系分則」中各校系之「選系說明」。\n\t\t\t"
            ),
          ]),
          _vm._v(" "),
          _c("p"),
          _vm._v(" "),
          _c("h5", [_vm._v("9.我要如何準備大學入學相關考試呢？")]),
          _vm._v(" "),
          _c("div", { staticClass: "qa-text" }, [
            _c("img", {
              attrs: { height: "25", src: _vm.content.qa, width: "25" },
            }),
            _vm._v("歡迎各位考生至"),
            _c(
              "a",
              { attrs: { href: "https://www.ceec.edu.tw/", target: "_blank" } },
              [_vm._v("大考中心網站")]
            ),
            _vm._v("查詢考試資訊、各項應試規則及注意事項：\n\t\t\t\t"),
            _c("br"),
            _vm._v("(一)\t考試資訊：\n\t\t\t\t"),
            _c("br"),
            _vm._v(
              "(1)\t大考中心為便利民眾瀏覽，於官網設有高中英語聽力測驗、學科能力測驗、分科測驗(110前指考)等考試專區，且於各考試專區下設有「考試訊息、試務專區、簡介、考試說明」等訊息，其中「考試訊息」刊登該次考試相關的即時資訊。\n\t\t\t\t"
            ),
            _c("br"),
            _vm._v(
              "(2)\t此外，各考試專區還有提供試題相關內容，如：考試說明，內含試題擧例；參考試卷，提供試卷可能架構；歷年試題，包括歷年正式考試試題與答案。適用108課綱之試題，可參考111至113學年度之學測及分科測驗各科試題、答題卷、參考答案與非選擇題評分原則。英聽測驗則可參考每年度公布的一卷試題示例，其中111至113年所公布者為依據108課綱所命試題。各項資訊，歡迎考生瀏覽或下載。\n\t\t\t\t"
            ),
            _c("br"),
            _vm._v(
              "(3)\t另大考中心網站「宣導與研習」專區，置放了各科命題方向等相關影音資料、簡報，包括部分試題分析的影片，考生可線上瀏覽或下載。\n\t\t\t\t"
            ),
            _c("br"),
            _vm._v(
              "(二)\t各項應試規則與應考注意事項：除簡章與各項考試專區之考試訊息外，可至「考生試務宣導」專區，透過影音媒介與各類說明，提醒考生各項應考注意事項。\n\t\t\t"
            ),
          ]),
          _vm._v(" "),
          _c("p"),
          _vm._v(" "),
          _c("h5", [
            _vm._v(
              "10.答題卷上印有本人應試號碼與姓名，依簡章規定考生仍須簽名？"
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "qa-text" }, [
            _c("img", {
              attrs: { height: "25", src: _vm.content.qa, width: "25" },
            }),
            _vm._v(
              "111學年度起使用卷卡合一答題卷，並新增簽名欄位，若考生未於「確認後考生簽名」欄以正楷簽全名，會依違規處理辦法扣減成績。\n\t\t\t\t"
            ),
            _c("br"),
            _vm._v("英聽：於考試開始鈴聲響畢後，應依播音指示簽名。\n\t\t\t\t"),
            _c("br"),
            _vm._v(
              "學測與分科測驗：各節次考試開始鈴響起，考生即可於答題卷上之「確認後考生簽名」欄，以正楷簽全名。\n\t\t\t\t"
            ),
            _c("br"),
            _vm._v(
              "另各項考試，如因特殊情況使用備用答題卷，卷上雖無考生本人應試號碼與姓名，仍須於「確認後考生簽名」欄以正楷簽全名。\n\t\t\t"
            ),
          ]),
          _vm._v(" "),
          _c("p"),
          _vm._v(" "),
          _c("h5", [
            _vm._v(
              "11.114分科測驗數學乙要考哪些內容？有哪些資訊可以參考？要如何準備？ "
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "qa-text" }, [
            _c("img", {
              attrs: { height: "25", src: _vm.content.qa, width: "25" },
            }),
            _vm._v(" "),
            _c("br"),
            _vm._v(
              "(1)\t114學年度分科測驗數學乙考科的測驗範圍包含10年級必修數學、11年級必修數學A類及數學B類均關聯的學習內容，以及12年級加深加廣選修數學乙類。如欲詳加了解該考科之測驗目標、測驗內容與試題舉例，可逕至大考中心網站-分科測驗(110前指考)-考試說明專區，參閱「分科測驗數學考科考試說明-114學年度起適用」(112年5月2日更新)。\n\t\t\t\t"
            ),
            _c("br"),
            _vm._v(
              "(2)\t又為進一步提供各界了解數學乙考科整份試卷之樣貌，大考中心亦於分科測驗(110前指考)-參考試卷專區，公布「114學年度起適用之分科測驗數學乙考科參考試卷」。此次公布之參考試卷計兩卷，每卷皆包含整卷試題、答題卷、參考答案、評分原則，以及試題解析等，提供大家了解組卷或有不同之題卷樣貌與題型配置，其中答題卷部分提供兩種版面形式：A3正式考試版與A4校園列印版，A3正式考試版可列印後做為模擬練習使用。\n\t\t\t\t"
            ),
            _c("br"),
            _vm._v(
              "(3)\t分科測驗數學乙考科題型包含單選、多選、選塡與混合題型，其中有關混合題型形式，另可以參閱大考中心網站學科能力測驗-歷屆試題及答題卷(111至113學年度)學測數學A、數學B試卷，或是分科測驗-歷屆試題及答題卷(111至113學年度)數學甲試卷，將有助考生了解混合題型的命題方式與答題卷設計。\n\t\t\t"
            ),
          ]),
          _vm._v(" "),
          _c("p"),
          _vm._v(" "),
          _c("h5", [
            _vm._v(
              "12.請問我不是108課綱的學生(如重考生、境外返國、高中肄業等)，可以報名111學年度以後的英聽、學測、術科、分科測驗等考試嗎？"
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "qa-text" }, [
            _c("img", {
              attrs: { height: "25", src: _vm.content.qa, width: "25" },
            }),
            _vm._v(
              "可以，考生符合報考資格均得報考，與是否為 108 課綱學生無涉。\n\t\t\t"
            ),
          ]),
          _vm._v(" "),
          _c("p"),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }