<template>
<v-menu
v-model="open"
:close-on-content-click="false"
:nudge-width="200"
offset-x
>
    <template v-slot:activator="{ on }">
        <v-btn
        color="indigo"
        dark
        v-on="on"
        >
        {{ user || '訪客' }}
        </v-btn>
    </template>

    <v-card>
        <v-list>
        <v-list-tile avatar>
            <v-list-tile-avatar>
            <img src="https://cdn.vuetifyjs.com/images/john.jpg" alt="">
            </v-list-tile-avatar>

            <v-list-tile-content>
            <v-list-tile-title>{{ user || '訪客' }}</v-list-tile-title>
            </v-list-tile-content>

        </v-list-tile>
        </v-list>

        <v-divider></v-divider>

    </v-card>
</v-menu>
</template>

<script>
export default {
    data () { return {
        open: false,
        user: ___.getCookie('account'),
    }},
}
</script>

<style lang="scss">
@import '~common/base';
</style>
