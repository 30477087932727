// navbar method

export default {
    // changes the drawer to permanent
    makeDrawerPermanent() {
        this.drawer.permanent = true
        // set the clipped state of the drawer and toolbar
        this.drawer.clipped = false
        this.toolbar.clippedLeft = false
    },
    // toggles the drawer variant (mini/full)
    toggleMiniDrawer() {
        this.drawer.mini = !this.drawer.mini
    },
    // toggles the drawer type (permanent vs temporary) or shows/hides the drawer
    toggleDrawer() {
        if (this.drawer.permanent) {
            this.drawer.permanent = !this.drawer.permanent
            // set the clipped state of the drawer and toolbar
            this.drawer.clipped = true
            this.toolbar.clippedLeft = true
        } else {
            // normal drawer
            this.drawer.open = !this.drawer.open
        }
    }
}
