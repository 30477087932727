<template>
<div class="page_home">
<div class="container">
    <header>
        <div class="top-bar">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-sm-6">
                        <a class="logo" href="/">
                            <img src="~page/_home/img/nsdua-logo.png" alt="logo">
                        </a>
                    </div>
                    <div class="col-sm-6 d-flex justify-content-center">
                        <router-link tag="v-btn" class="v-btn--flat" to="/service1">諮詢管道</router-link>
                        <router-link tag="v-btn" class="v-btn--flat" flat to="/download">資料下載</router-link>
                    </div>
                </div>
            </div>
        </div>
        <!-- Navigation -->
        <v-toolbar class="hidden-sm-and-down" color="blue darken-3">
            <v-spacer></v-spacer>
            <v-toolbar-items>
            <template v-for="item in navItems">
                <v-menu v-if="item.children" offset-y :key="item.name" open-on-hover>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" :to="item.to" flat>{{ item.name }}</v-btn>
                    </template>
                    <v-list>
                        <v-list-tile v-for="item in item.children" :key="item.to" :to="item.to" v-ripple>
                            {{ item.name }}
                        </v-list-tile>
                    </v-list>
                </v-menu>
                <v-btn v-else :key="item.to" :to="item.to" flat>{{ item.name }}</v-btn>
            </template>
            </v-toolbar-items>
            <v-spacer></v-spacer>
        </v-toolbar>
        <nav class="hidden-md-and-up navbar fixed-top navbar-expand-lg navbar-dark bg-dark fixed-top">
            <div class="container">
                <button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse"
                    data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false"
                    aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"><font color="#ffffff">☰</font></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarResponsive">
                    <ul class="navbar-nav ml-auto">
                        <li class="nav-item">
                            <router-link class="nav-link" to="/">首頁</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link class="nav-link" to="/about">理念與精神</router-link>
                        </li>
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownPortfolio"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                考試方式
                            </a>
                            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownPortfolio">
                                <router-link class="dropdown-item" to="/examinations/exam-1">學科能力測驗</router-link>
                                <router-link class="dropdown-item" to="/examinations/exam-2">分科測驗</router-link>
                                <router-link class="dropdown-item" to="/examinations/exam-3">術科考試</router-link>
                                <router-link class="dropdown-item" to="/examinations/exam-4">高中英文聽力測驗</router-link>
                            </div>
                        </li>
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownPortfolio"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                入學管道
                            </a>
                            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownPortfolio">
                                <router-link class="dropdown-item" to="/admissions/admission-1">繁星推薦</router-link>
                                <router-link class="dropdown-item" to="/admissions/admission-2">申請入學</router-link>
                                <router-link class="dropdown-item" to="/admissions/admission-3">分發入學</router-link>
                                <router-link class="dropdown-item" to="/admissions/admission-4">特殊選才</router-link>
                                <router-link class="dropdown-item" to="/admissions/admission-5">單獨招生</router-link>
                            </div>
                        </li>
                        <li class="nav-item">
                            <router-link class="nav-link" to="/relations">考試與入學管道</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link class="nav-link" to="/schedule">重要日程表</router-link>
                        </li>
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownPortfolio"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                114 考招調整
                            </a>
                            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownPortfolio">
                                <router-link class="dropdown-item" to="/revision/revision-1">招生調整</router-link>
                                <router-link class="dropdown-item" to="/revision/revision-2">考試調整</router-link>
                            </div>
                        </li>
						
						
						
		
                           <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" href="/qa" id="navbarDropdownPortfolio"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                常見問題
                            </a>
                            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownPortfolio">
                                <router-link class="dropdown-item" to="/qa1">考試</router-link>
                                <router-link class="dropdown-item" to="/qa2">招生</router-link>
                                <router-link class="dropdown-item" to="/qa3">諮詢</router-link>
                            </div>
                        </li>
                       
						
						
                    </ul>
                </div>
            </div>
        </nav>
    </header>

    <!-- Page Content -->
    <div class="page_container">
        <!-- Section -->
        <div class="row">
            <!-- Main Content -->
            <router-view></router-view>

            <!-- Sidebar Widgets Column -->
            <div class="col-md-4">
                <!-- Categories Widget -->
				
				
				
				  <div class="card my-4">
                    <h5 class="card-header">多元入學秘笈</h5>
                    <div class="card-body">
                        <ul class="list-unstyled mb-0">
						    <li>
                                <router-link tag="v-btn" class="v-btn--flat" to="/calendar">114 學年度行事曆</router-link>
                            </li>
							
                            <li>
                                <router-link tag="v-btn" class="v-btn--flat" to="/note">重要叮嚀事項</router-link>
                            </li>
                            <li>
                                <router-link tag="v-btn" class="v-btn--flat" to="/plan">入學方案簡表</router-link>
                            </li>
                            <li>
                                <router-link tag="v-btn" class="v-btn--flat" to="/flowchart">入學方案架構圖</router-link>
                            </li>
                            <li>
                                <router-link tag="v-btn" class="v-btn--flat" to="/agency">考試及招生單位</router-link>
                            </li>
                        </ul>
                    </div>
                </div>
				
				
				
                <div class="card my-4">
                    <h5 class="card-header">特殊選才招生訊息</h5>
                    <div class="card-body">
                        <ul class="list-unstyled mb-0">
                            <li>
                                <router-link tag="v-btn" class="v-btn--flat" to="/srecruit-1">特殊選才招生管道</router-link>
                            </li>
                            <li>
                                <v-btn flat href="https://srecruit.moe.edu.tw/" target="_blank">特殊選才招生專區</v-btn>
                            </li>
                        </ul>
                    </div>
                </div>
               
              
                <div class="card my-4">
                    <h5 class="card-header">考試簡章公告連結</h5>
                    <div class="card-body">
                        <ul class="list-unstyled mb-0">
                            <li>
                                <v-btn flat href="https://www.ceec.edu.tw/" target="_blank">英聽、學測、分科測驗</v-btn>
                            </li>
                            <li>
                                <v-btn flat href="https://www.cape.edu.tw/" target="_blank">術科考試</v-btn>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="card my-4">
                    <h5 class="card-header">招生簡章公告連結</h5>
                    <div class="card-body">
                        <ul class="list-unstyled mb-0">
                            <li>
                               <v-btn flat href="https://www.cac.edu.tw/star114/index.php" target="_blank">繁星推薦</v-btn>
                            </li>
                            <li>
                                <v-btn flat href="https://www.cac.edu.tw/apply114/index.php" target="_blank">申請入學</v-btn>
                            </li>
                            <li>
                                <v-btn flat href="https://www.uac.edu.tw/synopsis.htm" target="_blank">分發入學</v-btn>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="card my-4">
                    <h5 class="card-header">分發入學登記志願</h5>
                    <div class="card-body">
                        <ul class="list-unstyled mb-0">
                            <li>
                                <v-btn flat href="https://www.uac.edu.tw/113data/113single_web/" target="_blank">登記志願單機版</v-btn>
                            </li>
                            <li>
                                <v-btn flat href="https://www.youtube.com/watch?v=GJAftCswQoU" target="_blank">登記志願教學影片</v-btn>
                            </li>
                        </ul>
                    </div>
                </div>
          
		   <div class="card my-4">
                    <h5 class="card-header">ColleGo!系統</h5>
                    <div class="card-body">
                        <ul class="list-unstyled mb-0">
                            <li>
                                <router-link tag="v-btn" class="v-btn--flat text-none" to="/collego">ColleGo!系統簡介</router-link>
                            </li>
                            <li>
                                <v-btn flat href="https://collego.edu.tw/" target="_blank" class="text-none">ColleGo!系統連結</v-btn>
                            </li>
                        </ul>
                    </div>
                </div>
		  
                <div class="card my-4">
                    <h5 class="card-header">好站連結</h5>
                    <div class="card-body">
                        <ul class="list-unstyled mb-0">
                            <li>
                                <v-btn flat href="https://www.edu.tw/" target="_blank">教育部網站</v-btn>
                            </li>
                            <li>
                                <v-btn flat href="https://www.edu.tw/helpdreams/" target="_blank">圓夢助學網</v-btn>
                            </li>
                            <li>
                                <v-btn flat href="https://www.techadmi.edu.tw/" target="_blank">技專校院招策會</v-btn>
                            </li>
                        </ul>
                    </div>
                </div>
				
				
				
				
				
				
            </div>
        </div>
        <!-- /.row -->
    </div>
    <!-- /.container -->

    <!-- Footer -->
    <footer class="py-5 bg-dark">
        <div class="container">
            <p class="m-0 text-center text-white">Copyright &copy; 大學多元入學升學網 2024</p>
        </div>
        <!-- /.container -->
    </footer>
</div>
</div>
</template>

<script>
export default {
    data() {
        return {
            navItems: [
                { name: '首頁', to: '/' },
                { name: '理念與精神', to: '/about' },
                { name: '考試方式', to: '/examinations', children: [
                    { name: '學科能力測驗', to: '/examinations/exam-1' },
                    { name: '分科測驗', to: '/examinations/exam-2' },
                    { name: '術科考試', to: '/examinations/exam-3' },
                    { name: '高中英文聽力測驗', to: '/examinations/exam-4' },
                ]},
                { name: '入學管道', to: '/admissions', children: [
                    { name: '繁星推薦', to: '/admissions/admission-1' },
                    { name: '申請入學', to: '/admissions/admission-2' },
                    { name: '分發入學', to: '/admissions/admission-3' },
                    { name: '特殊選才', to: '/admissions/admission-4' },
                    { name: '單獨招生', to: '/admissions/admission-5' },
                ]},
                { name: '考試與入學管道', to: '/relations' },
                { name: '重要日程表', to: '/schedule' },
                { name: '114 考招調整', to: '/revision', children: [
                    { name: '招生調整', to: '/revision/revision-1' },
                    { name: '考試調整', to: '/revision/revision-2' },
                ]},
			    { name: '常見問題', to: '/qa', children: [
                    { name: '考試', to: '/qa1' },
                    { name: '招生', to: '/qa2' },
					{ name: '諮詢', to: '/qa3' },
                ]},
               
            ],
        }
    },
    watch: {
        async $route (to, from) {
            if (['/examinations', '/admissions', '/revision', '/faq'].includes(to.path))
                this.$router.replace(from.path)
        },
    },
    methods: {
    },
}
</script>

<style lang="scss">
@import '~common/base';
.page_home {
    font-family: 'DIN W01 Light','Lato','Noto Sans TC','PingFangSC-Light','微軟正黑體','Microsoft JhengHei','Hiragino Kaku Gothic Pro W3','ヒラギノ角ゴ Pro W3','LiHei Pro','儷黑 Pro','Meiryo','メイリオ','微軟雅黑體','Microsoft YaHei',sans-serif;

    .router-link-exact-active, .v-btn--active {
        background-color: rgba(0, 0, 0, 0.157);
    }

    .router-link-exact-active {
        border-radius: 20px;
    }
    
    .v-toolbar {
        .v-btn:hover {
            color: white;
        }
        .v-btn__content {
            color: white;
            font-size: 1.2rem;
        }
    }
    
    nav, footer {
        .container {
            padding: 0;
        }
    }

    .v-btn__content {
        font-size: 1.3rem;
    }

    .logo img {
        max-width: 100%;
    }

    ul, dl {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }
    
    .justify-content-end {
        -ms-flex-pack: end !important;
        justify-content: flex-end !important;
    }
    
    .justify-content-end a {
        margin-left: 10px;
        padding: 0;
        margin-bottom: 10px;
    }
    
    .top-bar img{
       margin-bottom: 20px;
    }
    
    .carousel-item {
      height: 35vh;
      min-height: 300px;
      background: no-repeat center center scroll;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
    }
    
    .portfolio-item {
      margin-bottom: 30px;
    }
    
    .ml-auto, .mx-auto {
        margin-right: auto;
    }
    
    .fixed-top {
        position: relative;
        margin-bottom: 10px;
        border-bottom: 1px solid rgba(0,0,0,.125);
        border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0; 
    }
    
    .bg-dark {
        background: #97c74e;
        background: -webkit-linear-gradient(top, #1e5799 0%,#2989d8 50%,#207cca 51%,#7db9e8 100%);
    }
    
    .card-header {
        background: #ffcc00;
        text-align: center;
    }
    
    .navbar-dark .navbar-nav .nav-link {
        font-size: 20px;
        color: #fff;
        margin-right: 20px;
    }
    
    .card-body ul li {
        font-size: 18px;
        text-align: center;
        padding-bottom: 10px;
    }
    
    .table td, .table th {
        vertical-align: middle;
    }
    
    .time {
        white-space:nowrap;
    }
    
    .reminder {
       padding: 40px;
       background: #f8f9fa;
    }
    
    .reminder ol {
       padding: 0 17px;
    }
    
    .py-5 {
        padding: 1rem 0 1rem 0!important;
    }
    
    @media (min-width:960px) and (max-width:1265px){
      .v-btn {
            padding: 0 10px;
        }
    }
}
</style>


<style lang="scss">
@import '~common/style-nsdua';
</style>