<template>
<div class="page_admin_updateUser">
    <v-container>
        <v-layout row wrap>
            <v-flex md8>

                <v-form ref="form">

                    <v-text-field
                    v-model="pwd"
                    label="原密碼"
                    type="password"
                    prepend-icon="lock"
                    :rules="[ v => !!v || '請輸入原密碼', ]"
                    ></v-text-field>

                    <v-text-field
                    v-model="pwd_new"
                    label="新密碼"
                    type="password"
                    prepend-icon="lock"
                    :rules="[ v => !!v || '請輸入新密碼', ]"
                    ></v-text-field>

                    <v-text-field
                    v-model="pwd_check"
                    label="新密碼確認"
                    type="password"
                    prepend-icon="lock"
                    :rules="[ v => !!v || '請再次輸入新密碼', ]"
                    ></v-text-field>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <span class="red--text">{{ msg }}</span>
                        <v-btn color="primary" @click="submit">更新</v-btn>
                    </v-card-actions>

                </v-form>
            </v-flex>

            <v-snackbar v-model="snackbar" :timeout="timeout">
                更新成功
                <v-btn flat @click="snackbar = false">OK</v-btn>
            </v-snackbar>
        </v-layout>
    </v-container>
</div>
</template>

<script>
export default {
    data() {
        return {
            msg: '',
            pwd: '',
            pwd_new: '',
            pwd_check: '',
            snackbar: false,
            timeout: 3000,
        }
    },
    methods: {
        async submit() {

            if (this.$refs.form.validate() && this.Checkpwd(this.pwd, this.pwd_new) && this.doubleCheckpwd(this.pwd_new, this.pwd_check) &&  this.thirdCheckpwd(this.pwd_new)) {
                
                const res = await ___.api.user.setData({
                    pwd:        this.pwd,
                    pwd_new:    this.pwd_new,
                    pwd_check:  this.pwd_check,
                })

                if (res.msg == 'invalid') {
                    this.msg = '更新失敗，原密碼不符，請重新試一次！ '
                } else if (res.msg == 'invalid:repeat') {
                    this.msg = '更新失敗，密碼不得重複使用！ '
                } else {
                    this.snackbar = true
                    this.msg = ''
                    this.pwd = ''
                    this.pwd_new = ''
                    this.pwd_check = ''
                    this.$refs.form.resetValidation()
                }
                
            } else {

                this.msg = ''

                if(! this.Checkpwd(this.pwd, this.pwd_new)) {
                    // alert('原密碼不能與新密碼設定一樣！')
                    this.msg = this.msg + '※ 原密碼不能與新密碼設定一樣！\n'
                }

                if(! this.doubleCheckpwd(this.pwd_new, this.pwd_check)) {
                    // alert('新密碼與新密碼確認不符合，請重新確認一次！')
                    this.msg = this.msg + '※ 新密碼與新密碼確認不符合！\n'
                } 

                if(! this.thirdCheckpwd(this.pwd_new)) {
                    // alert('密碼必須為8-12位且包含數字、大小寫英文字母的組合，請重新輸入一次！')
                    this.msg = this.msg + '※ 密碼必須為8-12位且包含數字、大小寫英文字母的組合！\n'
                }
            }
        },
        Checkpwd (pwd, pwd_new) {
            return pwd !== pwd_new
        },
        doubleCheckpwd (pwd_new, pwd_check) {
            return pwd_new === pwd_check
        },
        thirdCheckpwd (pwd_new) {
            return (pwd_new.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?!.*\s).{8,12}$/) !== null) 
        },
    },
}
</script>

<style lang="scss">
</style>
