var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page_home_download col-lg-8 mb-8" },
    [
      _c(
        "v-chip",
        {
          staticClass: "font-size:3rem;",
          attrs: { "text-color": "white", color: "primary" },
        },
        [_vm._v("諮詢管道")]
      ),
      _vm._v(" "),
      _c("br"),
      _c("br"),
      _vm._v(" "),
      _c("div", { staticClass: "card mb-4" }, [
        _c("div", { staticClass: "card-body reminder" }, [
          _c("div", { staticClass: "page_home_other" }, [
            _c("span", {
              staticClass:
                "v-chip font-size:3rem; theme--light primary white--text",
              attrs: { tabindex: "0" },
            }),
            _vm._v(" "),
            _c("div", [
              _c(
                "a",
                {
                  attrs: { href: "https://nsdua.moe.edu.tw", target: "_blank" },
                },
                [
                  _c("img", {
                    attrs: {
                      src: _vm.content.U2_01,
                      width: "100%",
                      border: "0",
                    },
                  }),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", [
              _c(
                "a",
                { attrs: { href: "https://collego.edu.tw", target: "_blank" } },
                [
                  _c("img", {
                    attrs: { src: _vm.content.U2_02, width: "100%" },
                  }),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", [
              _c(
                "a",
                {
                  attrs: { href: "https://www.jbcrc.edu.tw", target: "_blank" },
                },
                [
                  _c("img", {
                    attrs: { src: _vm.content.U2_03, width: "100%" },
                  }),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", [
              _c(
                "a",
                {
                  attrs: { href: "https://www.ceec.edu.tw", target: "_blank" },
                },
                [
                  _c("img", {
                    attrs: { src: _vm.content.U2_04, width: "100%" },
                  }),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", [
              _c(
                "a",
                {
                  attrs: { href: "https://www.cape.edu.tw", target: "_blank" },
                },
                [
                  _c("img", {
                    attrs: { src: _vm.content.U2_05, width: "100%" },
                  }),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", [
              _c(
                "a",
                { attrs: { href: "https://www.cac.edu.tw", target: "_blank" } },
                [
                  _c("img", {
                    attrs: { src: _vm.content.U2_06, width: "100%" },
                  }),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", [
              _c(
                "a",
                { attrs: { href: "https://www.uac.edu.tw", target: "_blank" } },
                [
                  _c("img", {
                    attrs: { src: _vm.content.U2_07, width: "100%" },
                  }),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div"),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }